<template>
  <div>
    <div class="container">
      <Header2 />
      <section  style="margin-top: 80px; margin-bottom: 100px">
                <div class="mx-auto logos" style="max-width: 400px; padding: 0;">
            <div style="display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap;">
          <v-img
            :src="require('../../assets/images/logo.svg')"
            class="my-3"
            contain
            style="width:20%"
          />
          <v-img
            :src="require('../../assets/logo-arica.png')"
            class="my-3"
            contain
            style="width:5%"
          />
          <v-img
            :src="require('../../assets/logo-core.png')"
            class="my-3"
            contain
            style="width:25%"
          />
            </div>
        </div>
        <h2>Lanzamiento Región Conectada</h2>

<div style="display: flex; justify-content: center; margin-top: 32px;">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/GLDC1OmxWOc?si=f-mdiTmv3iSQE2ZD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>


        <div v-show="loader" class="Galeria">
          <img
            @click="openImage('1.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/1.jpg"
            alt=""
          />
          <img
            @click="openImage('2.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/2.jpg"
            alt=""
          />
          <img
            @click="openImage('3.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/3.jpg"
            alt=""
          />
          <img
            @click="openImage('4.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/4.jpg"
            alt=""
          />
          <img
            @click="openImage('5.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/5.jpg"
            alt=""
          />
          <img
            @click="openImage('6.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/6.jpg"
            alt=""
          />
          <img
            @click="openImage('7.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/7.jpg"
            alt=""
          />
          <img
            @click="openImage('8.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/8.jpg"
            alt=""
          />
          <img
            @click="openImage('9.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/9.jpg"
            alt=""
          />
          <img
            @click="openImage('10.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/10.jpg"
            alt=""
          />
          <img
            @click="openImage('11.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/11.jpg"
            alt=""
          />
          <img
            @click="openImage('12.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/12.jpg"
            alt=""
          />
          <img
            @click="openImage('13.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/13.jpg"
            alt=""
          />
          <img
            @click="openImage('14.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/14.jpg"
            alt=""
          />
          <img
            @click="openImage('15.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/15.jpg"
            alt=""
          />
          <img
            @click="openImage('16.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/16.jpg"
            alt=""
          />
          <img
            @click="openImage('17.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/17.jpg"
            alt=""
          />
          <img
            @click="openImage('18.jpg')"
            class="img-sm"
            src="../../assets/images/galeriaRegion/18.jpg"
            alt=""
          />
        </div>

    <div v-show="!loader">
        <div class="text-center mt-5">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
    </div>

        <v-dialog v-model="dialog" width="800">
          <div style="position: relative">
            <v-icon
            @click="dialog= false"
              style="position: absolute; right: 10px"
              class="close-icon"
              large
              color="white darken-2"
            >
              mdi-close
            </v-icon>
            <img :src="selectedImage" alt="" />
          </div>
        </v-dialog>
      </section>

    <Footer />
  </div>
  </div>
</template>

<script>
import Header2 from "../commons/Header2.vue";
import Footer from "../commons/Footer.vue";

export default {
  components: {
    Header2,
    Footer,
  },
  data() {
    return {
      selectedImage: "",
      dialog: false,
      loader: false
    };
  },
  methods: {
    openImage(filename) {
      this.selectedImage = require("@/assets/images/galeriaRegion/" + filename);
      this.dialog = true;
    },
  },
  mounted() {
    setTimeout(() => {
        this.loader = true;
    }, 1000);

    const plugin = document.createElement("script");
    plugin.setAttribute("src", "/js/webflow.js");
    plugin.setAttribute("type", "text/javascript");
    document.head.appendChild(plugin);
    //
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62f4510e73201624361551ab"
    );
    plugin2.setAttribute(
      "integrity",
      "sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    );
    plugin2.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(plugin2);
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/normalize.css';
@import '../../assets/css/webflow.css';
@import '../../assets/css/valcar.webflow.css';
.Galeria {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

@media screen and (min-width: 600px) {
  .Galeria {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

@media screen and (min-width: 800px) {
  .Galeria {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

.img-sm:hover {
  filter: brightness(50%);
  cursor: pointer;
}
.img-sm {
  object-fit: cover;
}
.close-icon {
  position: absolute;
  right: 10px;
  opacity: .8;
  &:hover {
    cursor: pointer;
  }
}
</style>
