<template>
  <div style="background-color: #1B2A9C;">
    
  <section class="Hero">
<carousel adjustableHeight="true" :per-page="1" style="margin-top: 100px;">
  <slide>
    <div class="Slide Slide1" style="position: relative;">
            <div @click="dialogVideoLanzamiento = true;" class="preregistroContainer-hero btn-lanzamientoregion" style="position: absolute; top: 180px; left: 20px;">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Lanzamiento Región Conectada</p>
              </div>

            <div class="herobuttons-wrapper d-none d-md-flex">
<!--           <router-link  :to="{ path: '/ganadores-camaras'}" style="color: #1a1b1f; font-size: 12px;" >
            <div class="heroConcourseWrapper" >
              <img class="heroConcourseWrapper__Img" src="../../assets/soscam.png" alt="">
              <p class="heroConcourseWrapper__Text">Ganadores Sorteo de Cámaras</p>
            </div>
          </router-link> -->
          <router-link  :to="{ path: '/iduam-videos'}" style="color: #1a1b1f; font-size: 12px;" >
            <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Iduam - Cuenta Pública</p>
              </div>
              </router-link>

            <a href="https://pacientes.iduam.com/pre-registro-arica" target="_blank" style="z-index:999;">
              <div class="preregistroContainer-hero">
                <img class="preregistroImg-hero" src="../../assets/logo-iduam.svg" alt="Iduam" />
                <p class="preregistroText-hero" >PreRegistro Pulsera Iduam</p>
              </div>
            </a>
            <a href="https://www.regionconectada.com/registrate" target="_blank" style="z-index:999;">
              <div class="preregistroContainer-hero preregistroContainer-hero--region" style="max-height: 150px;">
                <img class="preregistroImg-hero preregistroImg-hero--region"  src="../../assets/images/logo-region.svg" alt="Iduam" />
                <p class="preregistroText-hero preregistroText-hero--region" >Registro Región Conectada</p>
              </div>
            </a>

          <a href="#iduam-description" class="hide-xl">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Iduam</p>
              </div>
          </a>
          <a href="#region-description" class="hide-xl">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Región Conectada</p>
              </div>
          </a>
              <div class="preregistroContainer-hero hide-xl" @click="$router.push('/testimoniales')">
                  <v-icon large color="white" >
                    mdi-video
                  </v-icon>
                <p class="preregistroText-hero text-center" >Testimoniales Lanzamiento Iduam</p>
              </div>
              
            </div>
        <div class="links-description">
                          <div @click="dialogVideoLanzamiento = true;" class="preregistroContainer-hero d-none d-lg-flex">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Lanzamiento Región Conectada</p>
              </div>
          <a href="#iduam-description">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Iduam</p>
              </div>
          </a>
          <a href="#region-description">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Región Conectada</p>
              </div>
          </a>
              <div class="preregistroContainer-hero"  @click="$router.push('/testimoniales')">
                  <v-icon large color="white" >
                    mdi-video
                  </v-icon>
                <p class="preregistroText-hero text-center" >Testimoniales Lanzamiento Iduam</p>
              </div>
        </div>
      <div class="div-block-10" style="padding-top: 24px;">

        <img style="opacity:1;" data-w-id="a8cef167-b31e-9b28-0e5d-f5e303958e27"
                  src="../../assets/images/Icon-logo.svg" loading="lazy" alt=""
                  class="image-7">
                <h1 data-w-id="a120922c-321d-3798-c027-500fe868cc89" style="opacity:1; margin-top: 0;" class="heading-2">Fundación
                  Valcar</h1>
                <p data-w-id="a120922c-321d-3798-c027-500fe868cc8b" style="opacity:1;text-align: justify;" class="paragraph-2"><strong>¡Bienvenidos a
                  Fundación Valcar!</strong> Somos una Organización sin fines de lucro creada en
                  el año 2019 cuyo objetivo principal es el desarrollo de programas y proyectos que permitan
                  ayudar y mejorar las políticas públicas tanto en el ámbito de la Seguridad, Salud y
                  Deportiva, a través del análisis de la información recopilada, la cual se obtendrá de la
                  interacción que generen los propios ciudadanos con Sistemas Tecnológicos de última
                  generación y que constituiran la herramienta principal que utilizara la Fundación Valcar
                  para llevar a cabo sus Estudios y Aportes.<br />
                  Es así, que nuestra Fundación en sus inicios tuvo como enfoque principal el Área
                  Deportiva, para luego ampliar su objeto a las Áreas de Seguridad y de Salud que en la
                  actualidad son las principales prioridades de solución que busca nuestra Población.
                  <br /><strong>¡Nuestra búsqueda va en la dirección de que nuestros innovadores Programas y
                  Proyectos, logren mejorar la calidad de vida de nuestros compatriotas!</strong> <br>
                    </p>
                  <strong style="color: #FFF;text-align:justify;">Los proyectos “Estudio Estadístico de Salud para la Región de Arica Parinacota”, Código BIP 40049734-0 (iDuam) y “Levantamiento Información Situación Delictual e Incivilidades Región de Arica y Parinacota”, Código BIP: 40045972-0 (Región Conectada), son financiados con fondos del Gobierno Regional de Arica y Parinacota.</strong>
                  <div class="logos" style="padding: 0; margin-top: 12px;">
                  <div style="width: 300px;display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap; background: #E0E2E9; border-radius: 15px; padding-left: 8px;margin: 0 auto;">
                    <v-img
                      :src="require('../../assets/images/logo.svg')"
                      class="my-3"
                      contain
                      style="width:20%"
                    />
                    <v-img
                      :src="require('../../assets/logo-arica.png')"
                      class="my-3"
                      contain
                      style="width:5%"
                    />
                    <v-img
                      :src="require('../../assets/logo-core.png')"
                      contain
                      style="width:25%"
                    />
                      </div>
                <div @click="dialogVideoLanzamiento = true;" class="preregistroContainer-hero d-md-none" style="margin: 0 auto; margin-bottom: 12px; margin-top: 6px;">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Lanzamiento Región Conectada</p>
              </div>

             <!-- mobile -->
                <div  style="position: absolute; z-index: 123; width: 100%; left: 0;" class="d-flex d-md-none">
             <div class="herobuttons-wrapper">
<!--           <router-link  :to="{ path: '/ganadores-camaras'}" style="color: #1a1b1f; font-size: 12px;" >
            <div class="heroConcourseWrapper" >
              <img class="heroConcourseWrapper__Img" src="../../assets/soscam.png" alt="">
              <p class="heroConcourseWrapper__Text">Ganadores Sorteo de Cámaras</p>
            </div>
          </router-link> -->
            <router-link  :to="{ path: '/iduam-videos'}" style="color: #1a1b1f; font-size: 12px;" >
            <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" - Cuenta Pública
              </div>
              </router-link>
            <a href="https://pacientes.iduam.com/pre-registro-arica" target="_blank" style="z-index:999;">
              <div class="preregistroContainer-hero">
                <img class="preregistroImg-hero" src="../../assets/logo-iduam.svg" alt="Iduam" />
                <p class="preregistroText-hero" >PreRegistro Pulsera Iduam</p>
              </div>
            </a>
            <a href="https://www.regionconectada.com/registrate" target="_blank" style="z-index:999;">
              <div class="preregistroContainer-hero preregistroContainer-hero--region" style="max-height: 150px;">
                <img class="preregistroImg-hero preregistroImg-hero--region"  src="../../assets/images/logo-region.svg" alt="Iduam" />
                <p class="preregistroText-hero preregistroText-hero--region" >Registro Región Conectada</p>
              </div>
            </a>

          <a href="#iduam-description" class="hide-xl">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Iduam</p>
              </div>
          </a>
          <a href="#region-description" class="hide-xl">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Región Conectada</p>
              </div>
          </a>
              <div class="preregistroContainer-hero hide-xl" @click="$router.push('/testimoniales')">
                  <v-icon large color="white" >
                    mdi-video
                  </v-icon>
                <p class="preregistroText-hero text-center" >Testimoniales Lanzamiento Iduam</p>
              </div>
            </div>
        <div class="links-description">
          <a href="#iduam-description">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Iduam</p>
              </div>
          </a>
          <a href="#region-description">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Región Conectada</p>
              </div>
          </a>
              <div class="preregistroContainer-hero" @click="$router.push('/testimoniales')">
                  <v-icon large color="white" >
                    mdi-video
                  </v-icon>
                <p class="preregistroText-hero text-center" >Testimoniales Lanzamiento Iduam</p>
              </div>
        </div>
</div>
    </div>
  </div>

    </div>
  </slide>
  <slide>
    <div class="Slide Slide2" style="position: relative;">
<!--       <div @click="dialogVideoLanzamiento = true;" class="preregistroContainer-hero btn-lanzamientoregion" style="position: absolute; top: 180px; left: 20px;">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Lanzamiento Región Conectada</p>
              </div> -->

<!--             <div class="herobuttons-wrapper d-none d-md-flex">
          <router-link  :to="{ path: '/ganadores-camaras'}" style="color: #1a1b1f; font-size: 12px;" >
            <div class="heroConcourseWrapper" >
              <img class="heroConcourseWrapper__Img" src="../../assets/soscam.png" alt="">
              <p class="heroConcourseWrapper__Text">Ganadores Sorteo de Cámaras</p>
            </div>
          </router-link>
            <a href="https://pacientes.iduam.com/pre-registro-arica" target="_blank" style="z-index:999;">
              <div class="preregistroContainer-hero">
                <img class="preregistroImg-hero" src="../../assets/logo-iduam.svg" alt="Iduam" />
                <p class="preregistroText-hero" >PreRegistro Pulsera Iduam</p>
              </div>
            </a>
            <a href="https://www.regionconectada.com/registrate" target="_blank" style="z-index:999;">
              <div class="preregistroContainer-hero preregistroContainer-hero--region" style="max-height: 150px;">
                <img class="preregistroImg-hero preregistroImg-hero--region"  src="../../assets/images/logo-region.svg" alt="Iduam" />
                <p class="preregistroText-hero preregistroText-hero--region" >Registro Región Conectada</p>
              </div>
            </a>

          <a href="#iduam-description" class="hide-xl">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Iduam</p>
              </div>
          </a>
          <a href="#region-description" class="hide-xl">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Región Conectada</p>
              </div>
          </a>
              <div class="preregistroContainer-hero hide-xl" @click="$router.push('/testimoniales')">
                  <v-icon large color="white" >
                    mdi-video
                  </v-icon>
                <p class="preregistroText-hero text-center" >Testimoniales Lanzamiento Iduam</p>
              </div>
              
            </div>
        <div class="links-description">
                          <div @click="dialogVideoLanzamiento = true;" class="preregistroContainer-hero d-none d-lg-flex">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Lanzamiento Región Conectada</p>
              </div>
          <a href="#iduam-description">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Iduam</p>
              </div>
          </a>
          <a href="#region-description">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Región Conectada</p>
              </div>
          </a>
              <div class="preregistroContainer-hero"  @click="$router.push('/testimoniales')">
                  <v-icon large color="white" >
                    mdi-video
                  </v-icon>
                <p class="preregistroText-hero text-center" >Testimoniales Lanzamiento Iduam</p>
              </div>
        </div> -->
      <div class="div-block-40">
              <div style="padding:0; padding-left: 8px; padding-right: 8px;" class="div-block-17-copy">
                <h1 class="heading-3">AREA DE SEGURIDAD</h1>
                <p class="paragraph-6-copy" style="text-align: justify;">A partir de lo expuesto, se destacan los Programas y Proyectos que contemplan desarrollos en materias de
                  Seguridad Pública a partir del uso de herramientas tecnológicas que le permitan a la ciudadanía mejorar su actual percepción de
                  inseguridad, sumado a la obtención a través del uso de estas tecnologías de la información necesaria que nos permita ayudar a que
                  la autoridad pueda tomar nuevas medidas de solución o mejorar las actuales Políticas Públicas en el ámbito de la Seguridad Pública
                  tanto en una determina comuna, región, etc. </p>
                <div class="div-block-20">
                  <div><img loading="lazy" src="../../assets/images/logo-SOSclick.svg" alt="" class="image-4"></div>
                  <div class="div-block-22"></div>
                  <div><img loading="lazy" src="../../assets/images/logo-sosclick-mujer.svg" alt="" class="image-3">
                  </div>
                  <div class="div-block-22"></div>
                  <div class="div-block-21"><img loading="lazy" src="../../assets/images/logo-region-conectad.svg"
alt="" class="image"></div>
                  <div class="div-block-22"></div>
                  <div><img loading="lazy" src="../../assets/images/logo-SOSdenucias.svg" alt="" class="image-2"></div>
                </div>
<!--                                   <div class="logos" style="padding: 0; margin-top: 12px;">
                  <div style="width: 300px;display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap; background: #E0E2E9; border-radius: 15px; padding-left: 8px;margin: 0 auto;">
                    <v-img
                      :src="require('../../assets/images/logo.svg')"
                      class="my-3"
                      contain
                      style="width:20%"
                    />
                    <v-img
                      :src="require('../../assets/logo-arica.png')"
                      class="my-3"
                      contain
                      style="width:5%"
                    />
                    <v-img
                      :src="require('../../assets/logo-core.png')"
                      contain
                      style="width:25%"
                    />
                      </div>
                <div @click="dialogVideoLanzamiento = true;" class="preregistroContainer-hero d-md-none" style="margin: 0 auto; margin-bottom: 12px; margin-top: 6px;">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Lanzamiento Región Conectada</p>
              </div> -->

             <!-- mobile -->
<!--                 <div  style="position: relative; z-index: 123; " class="d-flex d-md-none">
             <div class="herobuttons-wrapper">
          <router-link  :to="{ path: '/ganadores-camaras'}" style="color: #1a1b1f; font-size: 12px;" >
            <div class="heroConcourseWrapper" >
              <img class="heroConcourseWrapper__Img" src="../../assets/soscam.png" alt="">
              <p class="heroConcourseWrapper__Text">Ganadores Sorteo de Cámaras</p>
            </div>
          </router-link>
            <a href="https://pacientes.iduam.com/pre-registro-arica" target="_blank" style="z-index:999;">
              <div class="preregistroContainer-hero">
                <img class="preregistroImg-hero" src="../../assets/logo-iduam.svg" alt="Iduam" />
                <p class="preregistroText-hero" >PreRegistro Pulsera Iduam</p>
              </div>
            </a>
            <a href="https://www.regionconectada.com/registrate" target="_blank" style="z-index:999;">
              <div class="preregistroContainer-hero preregistroContainer-hero--region" style="max-height: 150px;">
                <img class="preregistroImg-hero preregistroImg-hero--region"  src="../../assets/images/logo-region.svg" alt="Iduam" />
                <p class="preregistroText-hero preregistroText-hero--region" >Registro Región Conectada</p>
              </div>
            </a>

          <a href="#iduam-description" class="hide-xl">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Iduam</p>
              </div>
          </a>
          <a href="#region-description" class="hide-xl">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Región Conectada</p>
              </div>
          </a>
              <div class="preregistroContainer-hero hide-xl" @click="dialog = true;">
                  <v-icon large color="white" >
                    mdi-video
                  </v-icon>
                <p class="preregistroText-hero text-center" >Testimoniales Lanzamiento Iduam</p>
              </div>
            </div>
        <div class="links-description">
          <a href="#iduam-description">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Iduam</p>
              </div>
          </a>
          <a href="#region-description">
              <div class="preregistroContainer-hero">
                <v-icon large color="white" >
                  mdi-video
                </v-icon>
                <p class="preregistroText-hero text-center" >Descripción Proyecto Región Conectada</p>
              </div>
          </a>
              <div class="preregistroContainer-hero" @click="dialog = true;">
                  <v-icon large color="white" >
                    mdi-video
                  </v-icon>
                <p class="preregistroText-hero text-center" >Testimoniales Lanzamiento Iduam</p>
              </div>
        </div>
</div>
                  </div> -->
              </div>
              
    </div>
    </div>
  </slide>
  <slide>
    <div class="Slide Slide3">
                  <div class="div-block-40">
              <div class="div-block-17-copy">
                <h1 class="heading-3">AREA DE SALUD</h1>
                <p class="paragraph-6-copy" style="text-align: justify;">A partir de lo expuesto, se destacan los Programas y Proyectos que contemplan desarrollos en el Área de Salud, 
                   a partir del uso de herramientas tecnológicas que le permitan a la ciudadanía mejorar su percepción de Atención de Salud.</p>
                <div class="div-block-20-copy">
                  <div class="div-block-41"><img loading="lazy" src="../../assets/images/Logo-iduam.svg" alt=""
                                                 class="image-4-copy"></div>
                  <div class="div-block-22"></div>
                  <div class="div-block-42"><img loading="lazy" src="../../assets/images/logo-alo-doctores.svg" alt=""
                                                 class="image-3"></div>
                </div>
              </div>
            </div>
    </div>
  </slide>
  <slide>
    <div class="Slide Slide4">
                  <div class="div-block-40">
              <div class="div-block-17-copy">
                <h1 class="heading-3"><strong>AREA DEPORTIVA.</strong></h1>
                <p class="paragraph-6-copy" style="text-align: justify;">A partir de
          lo expuesto, se destacan los Programas y Proyectos que contemplan desarrollos en materias del Deporte en general, con la
          utilización de Sistemas Tecnológicos que ayuden a mejorar los rendimientos deportivos y por ende la salud
          de cada una de las personas que sean beneficiarias de los diferentes Programas preparados por la Fundación.
          Estos se desarrollan a partir de la participación de profesionales destacados del medio Nacional.</p>
              </div>
            </div>
    </div>
  </slide>
</carousel>
  </section>


    <div class="section-2 wf-section">
      <div class="div-block-18">
        <v-slide-x-transition>

        <v-img src="../../assets/images/imageAreaSeguridad.png"
               class="image-areaseguridad"
        ></v-img>
        </v-slide-x-transition>
      </div>
      <div class="div-block-17"  id="seguridad"    >
        <h1 class="heading-3">PROGRAMAS Y PROYECTOS</h1>
        <v-slide-x-transition>
        <h3 data-w-id="85bb09dd-c366-b495-107b-fddc8c6d70bf" style="opacity:1" class="white--text">AREA DE SEGURIDAD</h3>
        </v-slide-x-transition>
        <p data-w-id="0c5b68b6-3583-24bd-3541-15b12c75e764" style="opacity:1;text-align: justify;" class="paragraph-6">
                  A partir de lo expuesto, se destacan los Programas y Proyectos que contemplan desarrollos en materias de
                  Seguridad Pública a partir del uso de herramientas tecnológicas que le permitan a la ciudadanía mejorar su actual percepción de
                  inseguridad, sumado a la obtención a través del uso de estas tecnologías de la información necesaria que nos permita ayudar a que
                  la autoridad pueda tomar nuevas medidas de solución o mejorar las actuales Políticas Públicas en el ámbito de la Seguridad Pública
                  tanto de una determina comuna, región, etc.<br></br>
                  Dentro de los Sistemas tecnológicos que utilizamos en esta Área, se destacan los siguientes:
                  <strong>“SOSCLICK”; "SOSCLICKLITE"; "SOSCLICKMUJER"; REGIÓN CONECTADA; “SOSDENUNCIAS”; COMUNA CONECTADA; SISTEMA DE ATENCIÓN CIUDADANA "CLICK2CALL”.</strong>

                </p>
        <div class="div-block-20">
          <div data-w-id="14e39eab-5525-ff79-cc32-c8cd72b07364" style="opacity:1"><img
              src="../../assets/images/logo-SOSclick.svg" loading="lazy" alt="" class="image-4"></div>
          <div class="div-block-22"></div>
          <div><img src="../../assets/images/logo-sosclick-mujer.svg" loading="lazy" style="opacity:1"
                    data-w-id="0a2e98a9-74eb-c5dd-9ab7-e7ac664c1075" alt="" class="image-3"></div>
          <div class="div-block-22"></div>
          <div class="div-block-21"><img src="../../assets/images/logo-region-conectad.svg" loading="lazy"
                                         style="opacity:1" data-w-id="08981fbe-1cf9-94f4-ca68-87fa5738bf63" alt=""
                                         class="image"></div>
          <div class="div-block-22"></div>
          <div><img src="../../assets/images/logo-SOSdenucias.svg" loading="lazy" style="opacity:1"
                    data-w-id="a8aa7c29-2b48-28fe-706c-87a77a9ffcd0" alt="" class="image-2"></div>
        </div>
      </div>
    </div>
    <div class="region_conectada wf-section">
      <div class="images-regionconectada w-container">
        <v-img src="../../assets/images/laptop0.png"
               class="image-appregionconectada"
               transition="slide-x-transition"
        ></v-img>
        <v-img src="../../assets/images/laptop1.png"
               class="image-panelregionconectada"
               transition="slide-x-transition"
        ></v-img>

        <a class="d-none d-md-flex" href="https://www.youtube.com/channel/UCZxQmE8InwzZNkcXPZKaJ-g" target="_blank">
          <img style="border-radius: 25px; width:70%; margin: 0 auto; margin-bottom: 140px;" src="../../assets/images/regionyoutube.jpeg" />
        </a>
      </div>
      <div class="container-4 w-container">
        <h1 data-w-id="d3066eed-259c-a789-f857-c3a26d63d169" style="opacity:1" class="heading-3"><strong>REGIÓN
          CONECTADA</strong></h1>
        <p data-w-id="d3afa96d-7510-889d-fe41-9b2ef0798be2" style="opacity:1;text-align: justify;" class="paragraph-6">
          La Plataforma <strong>"REGIÓN CONECTADA"</strong> es una Plataforma de comunicación que le
                permite a todo ciudadano realizar denuncias en tiempo real y acceder a comunicarse con
                un Call Center de Video Conferencia de nombre <strong>"Click2Call"</strong>, para lo cual el referido
                usuario deberá descargar en su smartphone la Aplicación Móvil <strong>"REGIÓN CONECTADA"</strong>.
                Esta Plataforma de Comunicación, además de contar con una Aplicación Móvil del mismo
                nombre, cuenta con un Panel WEB de comunicación total en donde se reciben las
                denuncias realizadas por los ciudadanos. Se debe destacar que en este Panel WEB no
                solo se reciben las denuncias realizadas mostrándolas en un plano con su geolocalización,
                sino que también, se recepciona el video de la denuncia generada, permitiendo además,
                que el operador se pueda comunicar desde el propio Panel WEB con la víctima vía Video
                Conferencia.<br></br>
                La Aplicación Móvil tiene cuatro elementos de comunicación, los cuales son: El Call Center
                de nombre <strong>"Click2Call"</strong>; Un sistema de generación de Denuncias en Video; Un Panel WEB
                de comunicación total y un sistema de noticias de la región. Si un ciudadano quiere
                denunciar un delito o incivilidad, puede utilizar la Aplicación Móvil para grabar un video y
                así enviarlo al Panel WEB. ¡Mantente seguro y protegido con <strong>"REGIÓN CONECTADA"</strong>!</p>
                <div class="container-descarga">
        <div data-w-id="afa3e829-c4f6-1a2b-1beb-85d7036eca67" style="opacity:1" class="div-block-13-copy">
          <a href="https://www.regionconectada.com/" class="link white--text">Mas información </a>
        </div>
        <div class="container-descargabtns">
        <a href="https://apps.apple.com/us/app/regi%C3%B3n-conectada/id6449751083">
          <img src="../../assets/images/appstore.png" alt="" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.naturalphone.regionconectada">
          <img src="../../assets/images/googleplay.png" alt="" />
        </a>
          </div>
                </div>
      </div>
    </div>

              <!--     VIDEO DESCRIPCIÓN IDUAM  -->

    <div id="region-description" class="region-description wf-section">
      <h3 class="region-description__Title">Descripción Proyecto Región Conectada</h3>

    <div class="region-description__Video">
      <iframe class="iframe-region"  src="https://www.youtube.com/embed/patjUWYwxo8?si=WVEjYMLj39wSrJeD" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    </div>
    <div class="section-3-0">
    <div class="section-3 wf-section">
      <div class="div-block-23">
        <div class="div-block-25">
<!--          <div data-w-id="83e5510e-21d4-fb72-a59e-8b2353b28d85" data-is-ix2-target="1" class="lottie-animation-2"-->
<!--               data-animation-type="lottie" data-src="documents/Telefonos-sos.json" data-loop="0" data-direction="1"-->
<!--               data-autoplay="0" data-renderer="svg" data-default-duration="1" data-duration="0"></div>-->

          <v-img src="../../assets/images/imgTelefonosSos.png"
                 class="image-appsosclick"
                 transition="scroll-x-reverse-transition"
                 contain
          ></v-img>
        </div>
        <v-scroll-x-transition>



        <div class="div-block-24">
          <h1 data-w-id="1bb8b2d4-c958-528f-deac-4540f444af1a" style="opacity:1" class="heading-3">
            <strong>SOSCLICK</strong>
          </h1>
          <p data-w-id="df357a35-3c05-fce9-240d-a9b34c2621d5" style="opacity:1;text-align: justify;" class="paragraph-6"><strong>"SOSCLICK"</strong>, es una Aplicación
            Móvil de Seguridad que permite ser utilizada para todo tipo de necesidades de Seguridad que los usuarios tengan. Esta Aplicación Móvil puede
            ser utilizada por: Comercio Detallista; Sustitución de Alarmas Comunitarias; Alertas de Mujeres Violentadas; Bullying; etc,
            que va directamente comunicado a un Panel WEB que tiene la característica de actuar como un Call Center de comunicación total, el cual no
            solo permite acceder a visualización de las informaciones de estas incivilidades incluso georreferenciadas, sino que actúa de forma inmediata
            a través de la comunicación en video conferencia que puede establecer el operador del Sistema con la víctima que está sufriendo la Emergencia.
            Independiente de esta comunicación en Video conferencia que posibilita el Sistema, permite que el propio smartphone de la víctima vaya grabando un video de
            la situación de incivilidad que está sufriendo, el cual es transmitido a éste Panel WEB y que puede servir de prueba ante los Tribunales de
            Justicia.<br></br>
            Este Sistema es un Servicio de Seguridad Preventiva y de Autocuidado tanto personal como de asociatividad con otras personas, tales como:
            familiares, vecinos o amigos, independiente que también genera las Alarmas necesarias que pueden ser captadas tanto por la Policía, como así
            también, por Seguridad Ciudadana Comunal.<br></br>
            Esta Plataforma de Seguridad es totalmente integral, la cual permite a los usuarios implementar una suite de seguridad de forma fácil. El
            sistema está desarrollado pensando tanto en la seguridad personal del usuario, como así también, en el Monitoreo de espacios tales como casas, locales
            comerciales, entre otros.<br></br>
            “La Plataforma <strong>SOSCLICK</strong>" permite además que cada usuario genere una red de seguridad personal en el propio Aplicativo Móvil, invitando a ser
            parte de esta tanto a familiares, amigos, vecinos, etc. Además, permite al usuario ser atendido remotamente por un Operador de Seguridad
            Ciudadana desde el Panel de Comunicación WEB <strong>"SOSCLICK"</strong>, mediante videollamada.<br></br>
            El usuario podrá activar alertas de seguridad cuando se sienta en peligro, estas alertas puede ser atendidas tanto por su red de seguridad
            personal como por los operadores del Panel WEB de comunicación total <strong>"SOSCLICK"</strong>, todo por video conferencia en un ambiente Multiusuarios.
</p>
          <div data-w-id="65b4fbec-338d-9dac-7de2-c2350aa464e8" style="opacity:1" class="div-block-13-copy">
            <a href="https://www.sosclick.cl/" target="_blank" class="link white--text">Mas información </a>
          </div>
        </div>
        </v-scroll-x-transition>

      </div>
    </div>
    <div class="section-3 wf-section">
      <div class="div-block-23">

        <div class="div-block-24-copy">

          <h1 data-w-id="1bb8b2d4-c958-528f-deac-4540f444af1a" style="opacity:1" class="heading-3">
            <strong>SOSCLICKLITE</strong>
          </h1>
          <p data-w-id="df357a35-3c05-fce9-240d-a9b34c2621d5" style="opacity:1;text-align: justify;" class="paragraph-6">
            <strong>"SOSCLICKLITE"</strong> se alza como un Servicio único en el ámbito de la Seguridad Personal que ofrece un abanico de
            características que se activan al momento en que el usuario pronuncie una palabra clave la cual servirá de detonante para que
            el Sistema se active y de forma inmediata comience a grabar y transmitir vía Internet a un servidor de nuestra empresa el video
            instantáneo que es capturado por la Aplicación <strong>"SOSCLICKLITE"</strong>  que estará previamente descargada en el smartphone que se
            le entregará a cada usuario. Adicionalmente a la activación de la grabación y transmisión del video a nuestros servidores el
            cual servirá de prueba ante los tribunales de justicia, se activará también una alarma sonora disuasiva y en forma simultánea el
            sistema enviará notificaciones de emergencia a las diferentes personas, familiares, amigos, locatarios, vecinos,
            etc, que componen la red propia de seguridad que tenga generada cada usuario, los cuales mediante un solo click en la notificación
            recibida, podrán comunicarse con la víctima por una simple video llamada. Además de las notificaciones enviadas a la red de seguridad
            personal del usuario, el sistema también enviara una notificación de Alarma al Panel WEB de comunicación total que podrá estar instalado
            ya sea en  dependencias de Seguridad Ciudadana Comunal, Policía, etc, con el objeto de que estos funcionarios también se comuniquen por
            Video Llamada con la víctima, generándose así una comunicación Multipunto simultánea de Video Conferencia, pudiendo brindarle la ayuda y
            contención necesaria e inmediata que el usuario necesite ante cualquier acto delictual o emergencia personal que éste tenga. Se debe
            destacar que este Panel WEB siempre podrá si así se determina, ver la ubicación o geolocalización de cada persona que tenga el Sistema
            <strong>"SOSCLICKLITE"</strong>  pudiendo incluso llamarlos por video llamada aún cuando estos no hayan generado ningún tipo de alerta.
            Esta última funcionalidad debería ser utilizada en Mercados como por ejemplo: Comerciantes o Locales Comerciales; Transportistas;
            Funcionarios Municipales, Funcionarios Policiales, Mujeres Violentadas, etc.
</p>
          <div data-w-id="65b4fbec-338d-9dac-7de2-c2350aa464e8" style="opacity:1" class="div-block-13-copy">
            <a href="https://www.sosclicklite.com/" target="_blank" class="link white--text">Mas información </a>
          </div>
        </div>

        <div class="div-block-25-0"><img src="../../assets/images/locatario2.png" loading="lazy" alt="" width="80%"></div>
      </div>
    </div>
  </div>
    <div class="section-4 wf-section">
      <div class="div-block-27">
        <div data-w-id="61a456f0-9c75-7518-d1f1-14a0f361b2a9" style="opacity:1" class="div-block-29">
          <h1 data-w-id="0d671a03-7450-3b36-100f-85de4e6069cb" style="opacity:1" class="heading-3"><strong>SOSCLICK
            MUJER</strong></h1>
          <p data-w-id="7e96afe3-40d7-c631-0a45-2454009fc6af" style="opacity:1;text-align: justify;" class="paragraph-6">
            El sistema <strong>"SOSCLICK MUJER"</strong>, es una Aplicación Móvil destinada a mujeres que están siendo
            víctimas de acoso sexual y violencia intrafamiliar, todo lo cual puede ser monitoreado a través de una plataforma digital
            online que perite una visualización en tiempo real del lugar en que se está generando la denuncia por parte de la víctima.<br></br>
            Esta Aplicación Móvil se descarga en cualquier smartphone, permitiendo a las mujeres violentadas acceder a dos alternativas de comunicación:
            La primera es poder comunicarse por Video Conferencia con un Call Center de Atención Psicológica que ellas requieran y la segunda
            alternativa de comunicación, es la activación de una Alerta de Acoso Sexual o de Violencia Intrafamiliar. En forma adicional esta Aplicación
             Móvil posibilita que la usuaria pueda invitar a familiares, amigas, vecinos, etc, a ser parte de su Red personal de Seguridad.

</p>
          <div data-w-id="7d38aad9-5633-a585-afee-865e0769bc0d" style="opacity:1" class="div-block-13-copy">
            <a href="https://www.sosclickmujer.com/" class="link white--text">Mas información </a>
          </div>
        </div>
        <div class="div-block-28">
          <div data-w-id="474bc60c-2800-75d1-8868-e6499e6b0c89" data-is-ix2-target="1" data-animation-type="lottie"
               data-src="../../assets/documents/Telefonos-sos-mujer.json" data-loop="0" data-direction="1"
               data-autoplay="0" data-renderer="svg" data-default-duration="1.0333333333333334" data-duration="0"></div>
          <v-img src="../../assets/images/imgTelefonosSosMujer.png"
                 class="image-appsosclick"
                 transition="scroll-y-reverse-transition"
          ></v-img>
        </div>
      </div>
    </div>
    <div class="section-8 wf-section"   id="salud" style="position:relative;">

      <a href="https://pacientes.iduam.com/pre-registro-arica" target="_blank">
      <div class="preregistroContainer">
        <img class="preregistroImg" src="../../assets/logo-iduam.svg" alt=">Iduam">
        <p class="preregistroText" >PreRegistro Pulsera Iduam</p>
      </div>
      </a>

      <div class="div-block-17-copy">
        <h3 class="heading-3">PROGRAMAS Y PROYECTOS</h3>
        <h3 data-w-id="6b4d4baa-c122-5871-65ee-6d2c9a10d376" style="opacity:1" class="white--text">AREA DE SALUD</h3>
        <v-scroll-x-transition>
        <p data-w-id="6b4d4baa-c122-5871-65ee-6d2c9a10d378" style="opacity:1" class="paragraph-6-copy"> 
                  A partir de lo expuesto, se destacan los Programas y Proyectos que contemplan desarrollos en el Área de Salud, 
                   a partir del uso de herramientas tecnológicas que le permitan a la ciudadanía mejorar su percepción de Atención de Salud.</p>
        </v-scroll-x-transition>
      </div>
    </div>
    <div class="iduam wf-section">
      <div class="div-block-30">
        <div class="div-block-32">
          <v-img src="../../assets/images/imgIduam.png"
                 transition="scroll-y-reverse-transition"
                 max-width="100vw"
                 width="100%"
                 contain
                 class="image-iduam"
          ></v-img>

        <a href="https://www.youtube.com/@iduam6042" target="_blank">
          <img style="border-radius: 25px; width:70%; margin:0 auto; display: flex;" src="../../assets/images/iduamyoutube.jpeg" />
        </a>
        </div>
        <div class="div-block-39">
          <h1 data-w-id="396afbe1-7348-f10d-f7ed-ab9622e677e6" style="opacity:1" class="heading-3">
            <strong>IDUAM</strong></h1>
          <p data-w-id="fbd9d28b-4075-37d3-6456-1d93c8f5abd5" style="opacity:1;text-align: justify;" class="paragraph-6">Es un Asistente de
            Salud personalizado llamado <strong>“Iduam”</strong>, la cual incorpora en su Aplicativo Móvil dos grandes desarrollos. 
            El primer desarrollo, incorporado en la Aplicación <strong>“Iduam”</strong>, permite el monitoreo
            constante y en todo momento por parte del propio paciente o ciudadano, tanto de su Presión Arterial; Saturación de Oxigeno,
            Ritmo Cardiaco y una Monitorización Electrocardiográfica. Esta medición la puede hacer en todo momento a través de una Aplicacion Móvil 
            conectada vía bluetooth a una pulsera inteligente del tipo smartband.<br></br>
            
            El segundo
            desarrollo, posibilita la Atención de Salud a distancia a través de Video Conferencia, entre el smartphone
            del paciente con un Call Center de Atención de Salud centralizado, o también descentralizado. Este
            desarrollo no es una Video Conferencia entre dos puntos tipo facetime, sino que es un Sistema desarrollado
            bajo el esquema de los actuales Call Center Telefónicos. Es decir, lleva estadísticas, graba las
            comunicaciones y maneja las cargas de entrada de llamadas para una buena atención o comunicación manejando
            las colas de espera. <br>Este desarrollo va orientado básicamente a tener una Atención preventiva a
            distancia en video y audio, que posibilita la descongestión de los Servicios de Salud Pública, llámese
            CESFAM, SAPU y Hospitales. </p>
          <div data-w-id="80b0b7b2-b713-2beb-1085-eba2d944bd05" style="opacity:1" class="div-block-13-copy">
            <a href="https://www.iduam.com/" class="link white--text">Mas información </a>
          </div>
        </div>
      </div>
    </div>

<!--     VIDEO DESCRIPCIÓN IDUAM  -->

    <div id="iduam-description" class="iduam-description wf-section">
      <h3 class="iduam-description__Title">Descripción Proyecto Iduam</h3>

    <div class="iduam-description__Video">
      <iframe class="iframe-iduam" src="https://www.youtube.com/embed/b9bn7kJ3UKk?si=zMTxkUVmc2gwxkBt" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    </div>


    <div class="alo_doctores wf-section">
      <div class="div-block-35">
        <div class="div-block-32-copy">
          <v-img src="../../assets/images/aloDoctores.png" transition="scroll-y-reverse-transition" contain></v-img>
        </div>
        <div class="div-block-31">
          <v-scroll-x-transition>

          <h1 data-w-id="0bafd987-833e-af5b-3c83-2ca3f65fb2da" style="opacity:1" class="heading-3"><strong>ALÓ
            DOCTORES</strong></h1>
          </v-scroll-x-transition>
          <p data-w-id="0bafd987-833e-af5b-3c83-2ca3f65fb2dd" style="opacity:1;text-align: justify;" class="paragraph-6">Es una Aplicación Móvil
            para smartphone desarrollada para mejorar en sus distintos procesos, la atención médica de pacientes
            postrados o con movilidad reducida que ven complicada la visita de control a algún centro médico u
            hospitalario, como así también, la Consulta Médica de carácter general que cualquier ciudadano de nuestro
            país requiera hacer. Esta Aplicación es una herramienta que funciona  gestionando un proceso de comunicación
            simple y completo entre el paciente y su médico, que sirve para la Atención o Consulta virtual de Salud o
            ronda Médica para pacientes postrados o con movilidad reducida, hasta el envío de Recetas Médicas;
              Licencias Médicas; Solicitud de Exámenes y manejo de una Ficha única de Salud por cada paciente e incluso
              el Servicio de Monitoreo en tiempo real de parámetros de Salud, tales como: Presión Arterial; Ritmo
              Cardiaco, Saturación de Oxigeno y Monitorización Electrocardiográfica.<br><br>Nuestra plataforma ofrece la comodidad de recibir atención médica
              desde la comodidad de su hogar para pacientes postrados o con problemas de movilidad, sin tener que sufrir
              largas esperas en consultorios o hospitales públicos. Con solo un click, puede solicitar atención médica a
              nuestro Centro de Atención Integral de Salud o a cualquier médico habilitado para atender de manera
              remota.<br><br></p>
          <div data-w-id="0bafd987-833e-af5b-3c83-2ca3f65fb2e1" style="opacity:1" class="div-block-13-copy">
            <a href="https://www.alodoctores.com//" class="link white--text">Mas información </a>
          </div>
        </div>
      </div>
    </div>
    <div data-w-id="287c695c-3923-703f-5976-aeeee962740f" style="opacity:1; background-color: #FFF;" class="area-deportiva wf-section"   id="deportes"   >
      <div class="div-block-45">
        <h3 class="heading-3 black--text" style="border-color: black ">PROGRAMAS Y PROYECTOS</h3>
        <h3 data-w-id="561bd7b6-9df8-5c4c-c92b-569b20e7172f" style="opacity:1" class="blacktext"><strong>AREA
          DEPORTIVA.</strong></h3>
        <p data-w-id="561bd7b6-9df8-5c4c-c92b-569b20e71732" style="opacity:1;text-align: justify;" class="paragraph-6 blacktext">A partir de
          lo expuesto, se destacan los Programas y Proyectos que contemplan desarrollos en materias del Deporte en general, con la
          utilización de Sistemas Tecnológicos que ayuden a mejorar los rendimientos deportivos y por ende la salud
          de cada una de las personas que sean beneficiarias de los diferentes Programas preparados por la Fundación.
          Estos se desarrollan a partir de la participación de profesionales destacados del medio Nacional.</p>
      </div>
    </div>


    <v-dialog v-model="dialog" v-if="dialog">
      <div style=" position: relative; background: black; display: flex; justify-content: center;"><v-icon
                @click="dialog = false"
                style="position: absolute; right: 10px"
                class="close-icon"
                large
                color="white darken-2"
              >
                mdi-close
              </v-icon>

              <div class="videos-testimonios">
                <video style="object-fit: fill; width: 400px; height: 600px" controls src="../../assets/video/testimonial1.mp4"></video>
                <video style="object-fit: fill; width: 400px; height: 600px" controls src="../../assets/video/testimonial2.mp4"></video>
              </div>
        
   
            </div>
          </v-dialog>

    <v-dialog width="800"  v-model="dialogVideoLanzamiento" v-if="dialogVideoLanzamiento">
      <div>
          <iframe class="youtube-video"   src="https://www.youtube.com/embed/GLDC1OmxWOc?si=f-mdiTmv3iSQE2ZD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </v-dialog>


  </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: "Home",
  components: {  Carousel, Slide },
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
    video: false,
    dialog: false,
    dialogVideoLanzamiento: false,
    settings: {
  "dots": true,
  "dotsClass": "slick-dots custom-dot-class",
  "edgeFriction": 0.35,
  "infinite": false,
  "speed": 500,
  "slidesToShow": 1,
  "slidesToScroll": 1
}
  }),
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss">

@import '../../assets/css/normalize.css';
@import '../../assets/css/webflow.css';
@import '../../assets/css/valcar.webflow.css';
.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.image-areaseguridad {
  object-fit: cover;
}

.images-regionconectada {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.image-appregionconectada {
/*  img responsive */
  width: 17%;
  height: auto;
  object-fit: contain;
}
.image-panelregionconectada {
  width: 50%;
  height: auto;
  object-fit: contain;
}
@media (max-width: 568px) {
  .image-panelregionconectada {
    width: 70%;
  }
}
.image-iduam {
  width: 100% ;
}
.image-appsosclick {
  /*width: 50% ;*/
}

  .herobuttons-wrapper {
  display: flex;
  position:absolute; 
  flex-wrap: wrap;
  left: 80px; 
  top: 150px;
  flex-direction: column;
  gap: 12px;
  top: auto;
  }

  @media (max-width: 568px) {
  .herobuttons-wrapper {
      position:relative; 
      flex-wrap: wrap;
      flex-direction: column;
      gap: 12px;
    }
  }


.preregistroContainer {
  cursor:pointer; 
  padding: 20px;
  border-radius: 20px; 
  display: flex; 
  flex-direction:column; 
  justify-content: center; 
  background: radial-gradient(circle, rgba(29,106,244,1) 0%, rgba(51,142,235,1) 100%);
  border: solid 1px #FFF;
}
.preregistroImg {
  width: 150px;
  margin:0 auto;
}
.preregistroText {
  color: #FFF; 
  margin-bottom:0 !important;
  font-size: 16px;
}

@media (max-width: 1075px) {
  .preregistroContainer {
    left: 50%;
    top: 90%; 
  }
  .preregistroText {
  margin-bottom:0 !important;
  text-align: center;
}
}

.preregistroContainer-hero {
  cursor:pointer; 
  padding: 20px;
  border-radius: 20px; 
  display: flex; 
  flex-direction:column; 
  justify-content: center; 
  background: radial-gradient(circle, rgba(29,106,244,1) 0%, rgba(51,142,235,1) 100%);
  border: solid 1px #FFF;
  width: 180px;
  height: 125px;
  &--region {
    background: radial-gradient(circle, #F5AF3E 0%, #F5AF3E 100%);
  }
}
@media (min-width: 1500px) {
  .preregistroContainer-hero {
    width: 235px;
  }
}

@media (max-width: 468px) {
  .preregistroContainer-hero {
    width: 150px;
  }
}


.preregistroImg-hero {
  width: 150px;
  margin:0 auto;
  &--region {
    width: 48px;
  }
}
@media (max-width: 468px) {
  .preregistroImg-hero {
  &--region {
    width: 35px;
  }
}
}

.preregistroText-hero {
  color: #FFF; 
  margin-bottom:0 !important;
  font-size: 16px;
  &--region {
    color: red;
  }
}

@media (max-width: 1500px) {
  .herobuttons-wrapper {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    left: 12px;
    bottom: 12px;
    justify-content: center;
  }

  .preregistroText-hero {
    margin-bottom:0 !important;
  text-align: center;
}
}


  .slider-hero {
    height: 100vh !important;
  }
@media (min-width: 465px) {
  .slider-hero {
    height: 90vh;
    max-height: 100vh !important;
  }
  .herobuttons-wrapper {
    width: 100%;
    top: 0;
  }
}


@media  (min-width: 992px) {
  .herobuttons-wrapper {
    left: 0;
    top: auto;
  }
}
      @media (min-width: 1500px) {
          .slider-hero {
          max-height: 90vh !important;
        }
          .herobuttons-wrapper {
            left: 80px;
            top: 20px;
          }
      }
    

.container-descarga {
 display: flex;
 flex-wrap: wrap;
}
.container-descargabtns {
display: flex;
justify-content: center;
flex-wrap: wrap;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .container-descargabtns {
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 0;
  }
  .container-descarga {
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
  }
}

.heroConcourseWrapper {
  height: 125px;
  cursor: pointer;
/*   position: absolute; */
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFAF00;
  border-radius: 25px;
  top: 200px;
  right: 150px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 13px 0px rgba(255,255,255,1);
  box-shadow: 0px 0px 13px 0px rgba(255,255,255,1);

  &__Img {
    width: 75px;
  }
  &__Text {

  }
}




@media (min-width: 1500px) {
  .heroConcourseWrapper {
    width: 235px;
    right: 300px;
  }
}

@media (min-width: 960px) {
    .heroConcourseWrapper {
      bottom: 8px;
      top: auto;
      left: 50%;
  }
}


@media (max-width: 468px) {
  .heroConcourseWrapper {
    width: 150px;
    display: flex;
    justify-content: center;
    text-align: center;
    top: 140px;
    right: 0;
    /* display: none; */
    img {
      width: 50px;
    }
    &__Text {
      margin-bottom: 0 !important;
    }
  }
}

.testimoniales-wrapper {
  z-index: 123;
  margin-bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 160px;
  color: #FFF;
  left: 90px;
  font-size: 18px;
  text-shadow: 4px -1px 15px rgba(255, 255, 255, 0.6);
  &:hover {
    text-decoration: underline;
  }
}
@media (max-width: 465px) {
  .testimoniales-wrapper {
    top: 100px;
    left: 20px;
  }
}


.iduam-description {
  padding-top: 50px;
  height: 700px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#0a3e84), to(#2c25d3));
  background-image: linear-gradient(180deg, #0a3e84, #2c25d3);
  &__Title {
    color: #FFF;
    margin-top: 0;
    padding-top: 20px;
    font-size: 32px;
    text-align: center;
  }
  &__Video {
    display: flex;
    margin: 0 auto;
  }
}
    @media (max-width: 568px) {
      .iduam-description {
        padding-top: 100px;
      }
    }


.region-description {
  padding-top: 100px;
  height: 700px;
  background-color: #F5AF3E;
  &__Title {
    color: #FFF;
    margin-top: 0;
    padding-top: 20px;
    font-size: 32px;
    text-align: center;
  }
  &__Video {
    display: flex;
    margin: 0 auto;
  }
}

.iframe-region {
  width: 100%;
  margin-top: 60px;
  height: 300px;
}

@media (min-width: 785px) {
  .iframe-region {
    width: 700px;
    height: 400px;
    padding-top: 40px;
    margin: 0 auto;
  }
}

@media (min-width: 1300px) {
  .iframe-region {
    width: 900px;
    height: 500px;
    padding-top: 40px;
    margin: 0 auto;
  }
}
.iframe-iduam {
  width: 100%;
  margin-top: 60px;
  height: 300px;
}

@media (min-width: 785px) {
  .iframe-iduam {
    width: 700px;
    height: 400px;
    padding-top: 40px;
    margin: 0 auto;
  }
}

@media (min-width: 1300px) {
  .iframe-iduam {
    width: 900px;
    height: 500px;
    padding-top: 40px;
    margin: 0 auto;
  }
}



  .videos-testimonios {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
@media (min-width: 568px) {
  .videos-testimonios {
    display: flex;
    flex-direction: row;
    gap: 120px;
  }
}

.links-description {
  position: absolute;
  bottom: 20px;
  left: 80px;
  display: flex;
  gap: 12px;
}

.links-description p {
  color: #FFF;
 text-shadow: -2px -2px 14px #FFF;
 font-size: 18px;
}

@media (max-width: 1500px) {
  .links-description {
    display: none;
  }
}




.hide-xl {
  display: flex;
}

@media (min-width: 1500px) {
  .hide-xl {
    display: none !important;
  }
}


@media (min-width: 465px) {
  .logos {
    max-width: 400px;
  }
}



.btn-lanzamientoregion {
  display: none;
}

@media (min-width: 990px) {
  .btn-lanzamientoregion {
  display: flex;
  }
}

@media (min-width: 1500px) {
  .btn-lanzamientoregion {
  display: none;
  }
}


.Slide {
  height: calc(100vh - 100px);
  background-position: 0 0,50% 50%;
  background-size: auto,cover;
  background-attachment: scroll,fixed;
}
.Slide1 {
  background-image: linear-gradient(139deg,rgba(44,37,211,.67),rgba(10,62,132,.7)),url(../../assets/images/fondo-1.jpg);
  display: flex;
  justify-content: center;
  min-height: 1400px;  
}

@media (min-width: 768px) {
  .Slide1 {
    height: 750px;
    min-height: 102vh;
    max-height: 130vh;
  }
}

@media (min-width: 1500px) {
  .Slide1 {
    min-height: calc(100vh - 100px);
  }
}

.Slide2 {
  background-image: linear-gradient(139deg,rgba(21, 161, 216, 0.67),rgba(132, 55, 10, 0.7)),url(../../assets/images/Grupo-2500-min.jpg);
  display: flex;
  justify-content: center;
  min-height: 1400px;  
  div {
    padding-top: 70px;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .Slide2 {
    min-height: 102vh;
        div{
      justify-content: center;
    }
  }
}
@media (min-width: 1500px) {
  .Slide2 {
    min-height: calc(100vh - 100px);
  }
}

.Slide3 {
  background-image: linear-gradient(139deg,rgba(44,37,211,.67),rgba(10,62,132,.7)),url(../../assets/images/iduam-guion-2.docx.jpg);
  display: flex;
  justify-content: center;
  min-height: 1400px;
  div {
    padding-top: 70px;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .Slide3 {
    min-height: 102vh;
    div{
      justify-content: center;
    }
  }
}
@media (min-width: 1500px) {
  .Slide3 {
    min-height: calc(100vh - 100px);
  }
}
.Slide4 {
  background-image: linear-gradient(139deg,rgba(63, 61, 97, 0.67),rgba(2, 12, 24, 0.7)),url(../../assets/images/big-06.jpg);
    display: flex;
  justify-content: center;
  min-height: 1400px;  
    div {
    padding-top: 70px;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .Slide4 {
    min-height: 102vh;
        div{
      justify-content: center;
    }
  }
}
@media (min-width: 1500px) {
  .Slide4 {
    min-height: calc(100vh - 100px);
  }
}
</style>
