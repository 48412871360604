<template>
  <main>
    <Header2 />
    <section class="container" style="margin-top: 120px">
      <div class="Videos">
        <h1>Videos que explican cada Proyecto</h1>
        <div class="mx-auto logos" style="max-width: 400px; padding: 0;">
            <div style="display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap;">
          <v-img
            :src="require('../../assets/images/logo.svg')"
            class="my-3"
            contain
            style="width:20%"
          />
          <v-img
            :src="require('../../assets/logo-arica.png')"
            class="my-3"
            contain
            style="width:5%"
          />
          <v-img
            :src="require('../../assets/logo-core.png')"
            class="my-3"
            contain
            style="width:25%"
          />
            </div>
        </div>
        <h2 class="Videos__Title">
          Estudios de levantamiento de Información y de
          Incivilidades de la Región de Arica y Parinacota
        </h2>

        <div class="Videos__Items">
          <div class="Videos__Items__Item">
            <div
              class="openVideo"
              @click="openVideo('https://www.youtube.com/embed/iKX0oMU98iY')"
            >
              <iframe
                class="Videos__Items__Item__Iframe"
                src="https://www.youtube.com/embed/iKX0oMU98iY"
              ></iframe>
            </div>
            <p class="Videos__Items__Item__Text">
              Descripción del proyecto con el detalle de encuestas y sondeos
              telefónicos a nivel masivo generados.<br />
              Creación del Big Data de datos de Incivilidades.
            </p>
          </div>
          <div class="Videos__Items__Item">
            <div
              class="openVideo"
              @click="openVideo('https://www.youtube.com/embed/DQJo_yI0JtQ')"
            >
              <iframe
                class="Videos__Items__Item__Iframe"
                src="https://www.youtube.com/embed/DQJo_yI0JtQ"
              ></iframe>
            </div>
            <p class="Videos__Items__Item__Text">
              Descripción del sistema de participación a través de la opinión
              ciudadana.<br />
              Creación del Big Data de datos de Incivilidades.
            </p>
          </div>
          <div class="Videos__Items__Item">
            <div
              class="openVideo"
              @click="openVideo('https://www.youtube.com/embed/SnEAgt5Wtck')"
            >
              <iframe
                class="Videos__Items__Item__Iframe"
                src="https://www.youtube.com/embed/SnEAgt5Wtck"
              ></iframe>
            </div>
            <p class="Videos__Items__Item__Text">
              Descripción de la aplicación móvil que podrán descargar en su
              smartphone los beneficiarios del proyecto.<br />
              Descripción del sistema de Call Center de Atención Ciudadana
              Click2Call. <br />
              Creación del Big Data de imágenes de Incivilidades.
            </p>
          </div>
          <div class="Videos__Items__Item">
            <div
              class="openVideo"
              @click="openVideo('https://www.youtube.com/embed/BJ_ZMsUAFeE')"
            >
              <iframe
                class="Videos__Items__Item__Iframe"
                src="https://www.youtube.com/embed/BJ_ZMsUAFeE"
              ></iframe>
            </div>
            <p class="Videos__Items__Item__Text">
              Descripción del sistema de Call Center de atención de denuncias e
              Incivilidades de Seguridad. <br />
            </p>
          </div>
        </div>
      </div>

      <div class="Videos">
        <h2 class="Videos__Title">
          Estudios Estadísticos de Salud para la Región de Arica Parinacota
        </h2>

        <div class="Videos__Items">
          <div class="Videos__Items__Item">
            <div
              class="openVideo"
              @click="openVideo('https://www.youtube.com/embed/ZjkQ5UVQdGM')"
            >
              <iframe
                class="Videos__Items__Item__Iframe"
                src="https://www.youtube.com/embed/ZjkQ5UVQdGM"
              ></iframe>
            </div>
            <p class="Videos__Items__Item__Text">
              Descripción del sistema de encuestas y sondeos telefónico de
              Salud.<br />
              Creación del Big Data de datos de enfermedades crónicas.
            </p>
          </div>
          <div class="Videos__Items__Item">
            <div
              class="openVideo"
              @click="openVideo('https://www.youtube.com/embed/A11n6uwDtp4')"
            >
              <iframe
                class="Videos__Items__Item__Iframe"
                src="https://www.youtube.com/embed/A11n6uwDtp4"
              ></iframe>
            </div>
            <p class="Videos__Items__Item__Text">
              Descripción del sistema preventivo de salud con smartband o
              pulsera inteligente de lectura de parámetros de salud con
              aplicación móvil Iduam. Descripción del Panel WEB de Atención
              Médica a distancia del sistema Iduam.
            </p>
          </div>
          <div class="Videos__Items__Item">
            <div
              class="openVideo"
              @click="openVideo('https://www.youtube.com/embed/y8P5Zd1RfJg')"
            >
              <iframe
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                class="Videos__Items__Item__Iframe"
                src="https://www.youtube.com/embed/y8P5Zd1RfJg"
              ></iframe>
            </div>
            <p class="Videos__Items__Item__Text">
              Creación del Big Data personal e histórico de parámetros de salud.
            </p>
          </div>
        </div>
      </div>

      <v-dialog v-if="dialogVideo" v-model="dialogVideo" width="800">
        <div style="position: relative; background-color: black">
          <v-icon
            @click="dialogVideo = false"
            class="video-icon"
            large
            color="white darken-2"
            style="position: absolute; right: 10px"
          >
            mdi-close
          </v-icon>
          <iframe
            class="iframe-drawer"
            :src="selectedVideo"
            frameborder="0"
          ></iframe>
        </div>
      </v-dialog>
    </section>
    <Footer />
  </main>
</template>

<script>
import Header2 from "../commons/Header2.vue";
import Footer from "../commons/Footer.vue";
export default {
  components: { Header2, Footer },
  data() {
    return {
      dialogVideo: false,
    };
  },
  methods: {
    openVideo(url) {
      this.selectedVideo = url;
      this.dialogVideo = true;
    },
  },
  mounted() {
    setTimeout(() => {
      this.loader = true;
    }, 1000);
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "/js/webflow.js");
    plugin.setAttribute("type", "text/javascript");
    document.head.appendChild(plugin);
    //
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62f4510e73201624361551ab"
    );
    plugin2.setAttribute(
      "integrity",
      "sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    );
    plugin2.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(plugin2);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/valcar.webflow.css";

.Videos {
  &__Title {
    text-align: justify;
  }
  &__Description {
    text-align: justify;
  }
  &__Items {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 12px;
    &__Item {
      width: 30%;
      &__Iframe {
        width: 100%;
        pointer-events: none;
      }
      &__Text {
        text-align: justify;
      }
    }
  }
}

.iframe-drawer {
  width: 100%;
  height: 450px;
}

@media (max-width: 768px) {
  .Videos {
    &__Title {
      text-align: left;
    }
    &__Description {
      text-align: justify;
    }
    &__Items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 12px;
      &__Item {
        width: 100%;
        &__Iframe {
          width: 100%;
          pointer-events: none;
        }
        &__Text {
          text-align: justify;
        }
      }
    }
  }
  .iframe-drawer {
    width: 100%;
    height: 200px;
  }
}

.openVideo {
  cursor: pointer;
}
</style>
