<template>
  <Pie id="my-chart-id" :chartOptions="chartOptions" :chartData="chartData" />
</template>

<script>
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "PieChart",
  components: { Pie },
  data() {
    return {
      chartData: {
        labels: [
          "Asalto a mano Armada",
          "Asalto en la vía Pública",
          "Portonazos o Encerronas",
          "Asalto en domicilio",
          "Inmigración Irregular",
          "Tráfico de Drogas",
          "Aumento de Homicidios",
          "Violencia Intrafamiliar",
          "Robo con Fuerza y con Violencia",
          "Otros",
        ],
        datasets: [
          { data: [924, 954, 648, 703, 2205, 766, 634, 187, 403, 818], 
          backgroundColor: ['#05172A', '#CBD1E0', '#4052D1', '#4D87E8', '#6DB69F', '#80DE8F', '#CB486C','#3572E4', '#7B20E7',  '#66D9ED' ] },
        ],
      },
      chartOptions: {
        plugins: {
            legend: {
                display: true,
            }
        },
        responsive: true,
      },
    };
  },
};
</script>
