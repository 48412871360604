import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from "vue2-google-maps/dist/components/cluster"
import VueCarousel from 'vue-carousel';

// import VueGtag from "vue-gtag"
Vue.component('GmapCluster', GmapCluster)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAVx5gJCh9MATmWOFIXOONY-YCc9R3yPlg',
		libraries: 'visualization',
  }
})
Vue.use(VueCarousel);
Vue.use(VueMeta)
Vue.config.productionTip = false

// Vue.use(VueGtag, {
//   // config: { id: "G-45S9M4LF35" }
// }, router)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
