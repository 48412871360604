<template>
  <div>
    <div class="container">
      <Header2 />
      <section style="margin-bottom: 100px; margin-top: 80px">
                <div class="mx-auto logos" style="max-width: 400px; padding: 0;">
            <div style="display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap;">
          <v-img
            :src="require('../../assets/images/logo.svg')"
            class="my-3"
            contain
            style="width:20%"
          />
          <v-img
            :src="require('../../assets/logo-arica.png')"
            class="my-3"
            contain
            style="width:5%"
          />
          <v-img
            :src="require('../../assets/logo-core.png')"
            class="my-3"
            contain
            style="width:25%"
          />
            </div>
        </div>
        <h2 class="Title">
          Resultado Encuesta y Sondeo Telefónico de Salud en la Región de Arica
          y Parinacota
        </h2>
        <div class="container-content" style="padding-right: 0">
          <div class="content-text" style="margin-top: 50px;">
            <p
              data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
              style="opacity: 1"
              class="paragraph-8"
            >
              Respondieron 1.732 personas aceptando contestar la Encuesta, como
              la tendencia se mantuvo y con el animo de abarcar la mayor
              cantidad de datos posible, se logró una contestación de 4011
              personas, la pregunta fue "Buen día, Lo estamos llamando de
              Fundación Valcar en base al Programa financiado por el GORE de
              Arica y Parinacota, el cual busca conocer las principales
              enfermedades de carácter crónico que sufren los ciudadanos de
              nuestra Región. Por favor si Usted sufre alguna de las
              enfermedades crónicas que más adelante se detallan o alguien en su
              hogar las padece, marque la alternativa que a Usted lo
              representa?".
            </p>

            <p style="font-weight: 800">Opciones</p>
            <ul class="paragraph-8">
              <li>Hipertensión Arterial, Marque 1.</li>
              <li>Diabetes, Marque 2.</li>
              <li>Obesidad, Marque 3.</li>
              <li>Todas Las Anteriores, Marque 4.</li>
              <li>Otras, Marque 5.</li>
            </ul>
            <br />
          </div>
          <div
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b85"
            style="opacity: 1"
            class="content-image"
          >
            <!--           <img
            src="@/assets/images/graficosalud.png"
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b86"
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 340.99261474609375px, 34vw"
            alt=""
            class="image-2"
          /> -->
            <div style="width: 350px">
              <PieChart />
            </div>
          </div>
        </div>

        <p
          data-w-id="45835889-a334-efa8-f1f2-05d3ba28c07a"
          style="opacity: 1"
          class="Sub"
        >
          1.274 personas escogieron la Alternativa 1, o bien, que sufren de
          HIPERTENSIÓN ARTERIAL como enfermedad crónica en la Región, todo lo
          cual muestra que esta patología representa el 31,8% del total de
          personas que padecen alguna de las enfermedades crónicas, 798 personas
          escogieron la Alternativa 2, o bien, que sufren de DIABETES como
          enfermedad crónica lo cual muestra que esta patología representa el
          19,9% , 412 personas escogieron la Alternativa 3, o bien, que sufren
          de OBESIDAD lo que representa el 10,3% , 521 personas escogieron la
          Alternativa 4, o bien, que sufren de TODAS LAS ANTERIORES, ES DECIR,
          HIPERTENSIÓN; DIABETES; OBESIDAD como enfermedades crónica en la
          Región todo lo cual muestra que estas tres patologías crónicas las
          padece el 13% de la población.
        </p>

        <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 50px;
          "
        >
          <h3>Georeferencia</h3>
          <div class="title-line"></div>
        </div>

        <!-- -->
        <div class="container-content-reverse" style="padding-right: 0">
          <div
            data-w-id="cafe0045-d304-79d9-8f68-af3adaed06f2"
            style="opacity: 1"
            class="content-image"
          >
            <img
              src="@/assets/images/mapasalud.jpg"
              style="opacity: 1; width: 100%"
              data-w-id="cafe0045-d304-79d9-8f68-af3adaed06f3"
              alt=""
            />
          </div>
        </div>
        <!--         -->

        <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 30px;
          "
        >
          <h3>Resumen por sexo</h3>
          <div class="title-line"></div>
        </div>

        <div style="padding-right: 0">
          <div>
            <p
              data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
              style="opacity: 1; text-align: justify"
              class="parrafo2"
            >
              Del total de las personas que respondieron la encuesta, las
              Mujeres representan un 53,4% y los Hombres el 46.6% restante, con
              respecto al los tramos etarios, tenemos que el grupo más
              representativo lo constituyen personas con edades mayores a 60
              años representando un 34,1%, seguido de las personas con edades
              entre los 50-59 años, representando un 19% y en tercer lugar se
              ubica el Rango Etario de personas con edades entre los 40-49 años
              representando un 18,9%. En cuarto y quinto lugar se ubican los
              rangos etarios de personas con edades entre los 30-39 años, con
              una representación de un 16,2% y el rango etario de personas con
              edades entre los 18-29 años con un 11,7. Cabe hacer notar que el
              estrato socioeconómico que mas participación tuvo fue el C3 o
              Clase Media-Baja con un 18%, seguido del estrato D o Clase Baja
              con un 16,7% y en tercer lugar se ubica el estrato C2 o Clase
              Media-Media con 15,3%.
            </p>
            <br />
          </div>

          <div class="container-items">
            <div class="itemMan">
              <img src="@/assets/images/man.png" alt="" />
              <p class="percent">46.6%</p>
            </div>
            <div class="itemWoman">
              <img src="@/assets/images/woman.png" alt="" />
              <p class="percent">53.4%</p>
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 50px;
          "
        >
          <h3>Tramo por edad</h3>
          <div class="title-line"></div>
        </div>

        <div class="percent-container" style="margin-top: 80px">
          <div class="percent-item">
            <p class="percent-1">11.6%</p>
            <p class="percent-2">466</p>
            <p class="percent-3">18-29</p>
          </div>
          <div class="percent-item">
            <p class="percent-1">16.3%</p>
            <p class="percent-2">652</p>
            <p class="percent-3">30-39</p>
          </div>
          <div class="percent-item">
            <p class="percent-1">18.9%</p>
            <p class="percent-2">758</p>
            <p class="percent-3">40-49</p>
          </div>
          <div class="percent-item">
            <p class="percent-1">19%</p>
            <p class="percent-2">763</p>
            <p class="percent-3">50-49</p>
          </div>
          <div class="percent-item">
            <p class="percent-1">34.2%</p>
            <p class="percent-2">1372</p>
            <p class="percent-3">60+</p>
          </div>
        </div>

        <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 50px;
          "
        >
          <h3>Total por opciones</h3>
          <div class="title-line"></div>
        </div>

        <v-simple-table style="margin-top: 30px">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  style="font-weight: 800; color: #000; font-size: 16px"
                >
                  Opciones
                </th>
                <th
                  class="text-center"
                  style="
                    font-weight: 800;
                    color: #000;
                    font-size: 16px;
                    text-align: center;
                  "
                >
                  Cantidad respuesta
                </th>
                <th
                  class="text-center"
                  style="font-weight: 800; color: #000; font-size: 16px"
                >
                  %
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tablaSalud" :key="item.opciones">
                <td style="font-weight: 500">{{ item.opciones }}</td>
                <td
                  style="
                    font-size: 22px;
                    color: #007fff;
                    display: flex;
                    justify-content: center;
                  "
                >
                  {{ item.respuestas }}
                </td>
                <td
                  style="
                    font-size: 30px;
                    font-weight: 800;
                    color: #007fff;
                    text-align: center;
                  "
                >
                  {{ item.porcentaje }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <h2 class="Title" style="margin-top: 100px;">
          Encuesta servicio de Salud Región de Arica y Parinacota.
        </h2>

        <div class="container-content" style="padding-right: 0">
          <div class="content-text">
            <p
              data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
              style="opacity: 1"
              class="paragraph-8"
            >
              La pregunta que se realizo por vía telefónica fue la siguiente:
              Necesitamos saber cuál es su opinión respecto de la atención en
              los servicios de salud tanto públicos como privados que hay la
              Región de Arica y Parinacota.
            </p>

            <p style="font-weight: 800">Opciones</p>
            <ul class="paragraph-8">
              <li>Muy bueno, Marque 1.</li>
              <li>Bueno, Marque 2.</li>
              <li>Regular, Marque 3.</li>
              <li>Malo, Marque 4.</li>
              <li>Muy Malo, Marque 5.</li>
            </ul>
            <br />
          </div>
          <div
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b85"
            style="opacity: 1"
            class="content-image"
          >
            <div style="width: 350px">
              <PieChartSalud2 />
            </div>
          </div>
        </div>


                <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 50px;
          "
        >
          <h3>Georeferencia</h3>
          <div class="title-line"></div>
        </div>

        <!-- -->
        <div class="container-content-reverse" style="padding-right: 0">
          <div
            data-w-id="cafe0045-d304-79d9-8f68-af3adaed06f2"
            style="opacity: 1"
            class="content-image"
          >
            <img
              src="@/assets/images/mapasalud.jpg"
              style="opacity: 1; width: 100%"
              data-w-id="cafe0045-d304-79d9-8f68-af3adaed06f3"
              alt=""
            />
          </div>
        </div>
        <!--         -->

        <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 30px;
          "
        >
          <h3>Resumen por sexo</h3>
          <div class="title-line"></div>
        </div>

              <div style="padding-right: 0; margin-top:50px;">
          <div class="container-items">
            <div class="itemMan">
              <img src="@/assets/images/man.png" alt="" />
              <p class="percent">53.6%</p>
            </div>
            <div class="itemWoman">
              <img src="@/assets/images/woman.png" alt="" />
              <p class="percent">46.4%</p>
            </div>
          </div>
        </div>

           <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 50px;
          "
        >
          <h3>Tramo por edad</h3>
          <div class="title-line"></div>
        </div>

        <div class="percent-container" style="margin-top: 80px">
          <div class="percent-item">
            <p class="percent-1">9.2%</p>
            <p class="percent-2">99</p>
            <p class="percent-3">18-29</p>
          </div>
          <div class="percent-item">
            <p class="percent-1">23.9%</p>
            <p class="percent-2">255</p>
            <p class="percent-3">30-39</p>
          </div>
          <div class="percent-item">
            <p class="percent-1">25.7%</p>
            <p class="percent-2">275</p>
            <p class="percent-3">40-49</p>
          </div>
          <div class="percent-item">
            <p class="percent-1">18.8%</p>
            <p class="percent-2">201</p>
            <p class="percent-3">50-49</p>
          </div>
          <div class="percent-item">
            <p class="percent-1">22.3%</p>
            <p class="percent-2">238</p>
            <p class="percent-3">60+</p>
          </div>
        </div>

        <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 50px;
          "
        >
          <h3>Total por opciones</h3>
          <div class="title-line"></div>
        </div>

        <v-simple-table style="margin-top: 30px">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  style="font-weight: 800; color: #000; font-size: 16px"
                >
                  Opciones
                </th>
                <th
                  class="text-center"
                  style="
                    font-weight: 800;
                    color: #000;
                    font-size: 16px;
                    text-align: center;
                  "
                >
                  Cantidad respuesta
                </th>
                <th
                  class="text-center"
                  style="font-weight: 800; color: #000; font-size: 16px"
                >
                  %
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tablaSalud2" :key="item.opciones">
                <td style="font-weight: 500">{{ item.opciones }}</td>
                <td
                  style="
                    font-size: 22px;
                    color: #007fff;
                    display: flex;
                    justify-content: center;
                  "
                >
                  {{ item.respuestas }}
                </td>
                <td
                  style="
                    font-size: 30px;
                    font-weight: 800;
                    color: #007fff;
                    text-align: center;
                  "
                >
                  {{ item.porcentaje }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header2 from "../commons/Header2.vue";
import Footer from "../commons/Footer.vue";
import PieChart from "../charts/PieChart.vue";
import PieChartSalud2 from "../charts/PieChartSalud2.vue";


export default {
  components: {
    Header2,
    Footer,
    PieChart,
    PieChartSalud2
  },
  data() {
    return {
      tablaSalud: [
        {
          opciones: "Hipertensión Arterial,Marque 1.",
          respuestas: "1274",
          porcentaje: "31.8%",
        },
        {
          opciones: "Diabetes, Marque 2.",
          respuestas: "798",
          porcentaje: "19.9%",
        },
        {
          opciones: "Obesidad, Marque 3.",
          respuestas: "412",
          porcentaje: "10.3%",
        },
        {
          opciones: "Todas Las Anteriores, Marque 4.",
          respuestas: "521",
          porcentaje: "13%",
        },
        {
          opciones: "Otras, Marque 5.",
          respuestas: "1006",
          porcentaje: "25.1%",
        },
        { opciones: "Total", respuestas: "4011", porcentaje: "100%" },
      ],
      tablaSalud2: [
        {
          opciones: "Muy bueno, Marque 1.",
          respuestas: "130",
          porcentaje: "12.2%",
        },
        {
          opciones: "Bueno, Marque 2.",
          respuestas: "147",
          porcentaje: "13.8%",
        },
        {
          opciones: "Regular, Marque 3.",
          respuestas: "335",
          porcentaje: "31.4%",
        },
        {
          opciones: "Malo, Marque 4.",
          respuestas: "286",
          porcentaje: "26.8%",
        },
        {
          opciones: "Muy Malo, Marque 5.",
          respuestas: "168",
          porcentaje: "15.8%",
        },
        { opciones: "Total", respuestas: "1067", porcentaje: "100%" },
      ]
    };
  },
      mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "/js/webflow.js");
    plugin.setAttribute("type", "text/javascript");
    document.head.appendChild(plugin);
    //
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62f4510e73201624361551ab"
    );
    plugin2.setAttribute(
      "integrity",
      "sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    );
    plugin2.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(plugin2);
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 90%;
}
@media (max-width: 568px) {
  .container {
    width: 100%;
  }
}
.Title {
  font-size: 22px;
  display: flex;
  justify-content: center;
  color: #000;
  background: rgb(1, 91, 254);
  background: linear-gradient(
    90deg,
    rgba(1, 91, 254, 1) 0%,
    rgba(65, 234, 216, 1) 100%
  );
  color: #fff;
  padding: 30px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 23px;
}
.Sub {
  margin: 0 auto;
  margin-top: 50px;
  background-color: rgb(1, 91, 254);
  color: #fff;
  padding: 25px;
  width: fit-content;
  display: flex;
  border-radius: 23px;
  max-width: 1200px;
  font-weight: 500;
  text-align: justify;
}
@media (max-width: 568px) {
  .Sub {
    width: 96vw;
  }
}

h3 {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 800;
  margin: 0;
}

.title-line {
  width: 100%;
  height: 9px;
  background: linear-gradient(
    90deg,
    rgba(1, 91, 254, 1) 0%,
    rgba(65, 234, 216, 1) 100%
  );
  border-radius: 25px;
  margin-left: 20px;
}
.parrafo2 {
  color: #707070;
  text-align: justify;
  margin-top: 30px;
}
/* @media (max-width: 568px) {
  .parrafo2 {
    width: 91vw;
  }
}
 */

//resumen por sexo
.container-items {
  display: flex;
  justify-content: space-between;
  gap: 80px;
}
@media (max-width: 568px) {
  .container-items {
    flex-wrap: wrap;
    gap: 60px;
  }
}

.itemMan {
  background: transparent linear-gradient(111deg, #0058ff 0%, #41ebd8 100%) 0%
    0% no-repeat padding-box;
  border-radius: 33px;
  width: 50%;
  padding: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 568px) {
  .itemMan {
    width: 100%;
  }
}
.itemWoman {
  padding: 15px;
  background: transparent linear-gradient(252deg, #ff00ba 0%, #de41eb 100%) 0%
    0% no-repeat padding-box;
  border-radius: 33px;
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 568px) {
  .itemWoman {
    width: 100%;
  }
}

.percent {
  font-size: 50px;
  font-weight: 800;
  color: #fff;
  display: flex;
}

//tramo por edad

.percent-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.percent-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #e4e4e4;
}

.percent-1 {
  color: #007fff;
  font-size: 32px;
  font-weight: 800;
}
.percent-2 {
  font-size: 26px;
}
.percent-3 {
  font-size: 20px;
  font-weight: 800;
}
@media (max-width: 568px) {
  .percent-1 {
    color: #007fff;
    font-size: 22px;
    font-weight: 800;
  }
  .percent-2 {
    font-size: 18px;
  }
  .percent-3 {
    font-size: 18px;
    font-weight: 800;
  }
}

.container-content {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}
@media (max-width: 768px) {
  .container-content {
    flex-direction: column;
  }
  .content-text {
    width: 90vw !important;
  }
  .content-image {
    width: 90vw !important;
  }
}
.container-content-reverse {
  margin: 0 auto;
  margin-top: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}
.content-text {
  width: 50%;
  max-width: 500px;
  text-align: justify;
}
.content-image {
  justify-content: center;
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .container-content-reverse {
    flex-direction: column;
    width: 100vw;
  }
}

.image-2 {
  width: 100%;
}

@media (min-width: 768px) {
  .image-2 {
    width: 70%;
    display: flex;
    margin: 0 auto;
  }
}
</style>
