<template>
  <div class="navbar-logo-left wf-section" style="z-index: 200;">
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-logo-left-container shadow-three w-nav">
      <div>
        <div class="navbar-wrapper">
          <a href="/" class="navbar-brand w-nav-brand"><img src="../../assets/images/logo.svg" loading="lazy" height="90" alt="" class="image-6"></a>
          <nav role="navigation" class="nav-menu-wrapper w-nav-menu" style="align-items: left;">
            <ul role="list" class="nav-menu-two w-list-unstyled">

              <li>
                <router-link :to="{ path: '/nosotros'}" class="nav-link">Nosotros</router-link>
              </li>
              <li>
                <router-link :to="{ path: '/videos'}" class="nav-link">Videos</router-link>
              </li>
        <v-menu offset-y style="z-index: 34242;">
          <template v-slot:activator="{ on, attrs }">
        <li
          v-bind="attrs"
          v-on="on"
          :style="
        !$vuetify.breakpoint.mobile
          ? 'font-size: 12px; padding-top:1px;'
          : 'font-size: 22px; margin-left:8px;'
      "

        >
          Encuestas
        </li>
      </template>
      <v-list>
        <v-list-item>
          <router-link style="width:100%" :to="{ path: '/encuestas/incivilidades' }" class="nav-link">Incivilidades</router-link>
        </v-list-item>
        <v-list-item>
          <router-link style="width:100%" :to="{ path: '/encuestas/salud' }" class="nav-link">Salud</router-link>
        </v-list-item>
      </v-list>
    </v-menu>
              <li id="item-nav3">
                <router-link :to="{ path: '/', hash: 'seguridad' }" class="nav-link">Programas de Seguridad</router-link>
              </li>
              <li id="item-nav4">
                <router-link :to="{ path: '/', hash: 'salud' }" class="nav-link">Programas de Salud</router-link>
              </li>      
              <li id="item-nav5">
                <router-link :to="{ path: '/', hash: 'deportes' }" class="nav-link">Programas Deportivos</router-link>
              </li>        
              <li>
          <v-menu offset-y style="z-index: 34242;">
          <template v-slot:activator="{ on, attrs }">
        <li
          v-bind="attrs"
          v-on="on"
                    :style="
        !$vuetify.breakpoint.mobile
          ? 'font-size: 12px;padding-top: 2px;'
          : 'font-size: 22px; margin-left:8px;'
      "
        >
          Lanzamientos
        </li>
      </template>
      <v-list  style="z-index: 34242;">
        <v-list-item>
          <router-link style="width:100%" :to="{ path: '/lanzamiento-regionconectada' }" class="nav-link">Región Conectada</router-link>
        </v-list-item>
        <v-list-item>
          <router-link style="width:100%" :to="{ path: '/lanzamiento-iduam' }" class="nav-link">Lanzamiento Iduam</router-link>
        </v-list-item>
      </v-list>
    </v-menu>
              </li>        
              <li class="denuncias-item">
                <router-link :to="{ path: '/denuncias-destacadas' }" class="nav-link" >Denuncias</router-link>
              </li>        
              <li id="item-nav2" style="height: 48px;" class="d-flex d-sm-flex d-md-none">
                <router-link :to="{ path: '/', hash: '#iduam-description' }" class="nav-link">Descripción Iduam</router-link>
              </li>
              <li id="item-nav"  style="height: 48px;" class="d-flex d-sm-flex d-md-none">
                <router-link :to="{ path: '/', hash: '#region-description' }" class="nav-link">Descripción Región Conectada</router-link>
              </li>

              <!--li>
                <a href="https://www.sosclicklite.com/" class="nav-link">SosclickLite</a>
              </li>              
              <li>
                <a href="https://www.sosclickmujer.com/" class="nav-link">Sosclick Mujer</a>
              </li>
              <li class="list-item">
                <a href="https://www.regionconectada.com/" class="nav-link">Región Conectada</a>
                <a href="https://www.iduam.com/" class="nav-link">Iduam</a>
                <a href="https://www.alodoctores.com/" class="nav-link">Aló Doctores</a>
              </li>
              <li class="mobile-margin-top-10">
                <a href="#" class="button-primary w-button white--text">Contactar</a>
              </li-->
            </ul>
          </nav>
          <div class="menu-button w-nav-button" id="boton">
            <div class="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    isMobile: false,
  }),
  mounted() {
  if (window.screen.width <= 990) {
    const item = document.getElementById('item-nav')
    item.addEventListener('click', (() => {
      document.getElementById('boton').click();
    }))
      const item2 = document.getElementById('item-nav2')
    item2.addEventListener('click', (() => {
      document.getElementById('boton').click();
    }))
      const item3 = document.getElementById('item-nav3')
    item3.addEventListener('click', (() => {
      document.getElementById('boton').click();
    }))
      const item4 = document.getElementById('item-nav4')
    item4.addEventListener('click', (() => {
      document.getElementById('boton').click();
    }))
      const item5 = document.getElementById('item-nav5')
    item5.addEventListener('click', (() => {
      document.getElementById('boton').click();
    }))
      const item6 = document.getElementById('item-nav6')
    item6.addEventListener('click', (() => {
      document.getElementById('boton').click();
    }))
  }
  }
  // beforeDestroy() {
  //   if (typeof window !== "undefined") {
  //     window.removeEventListener("resize", this.onResize, { passive: true });
  //   }
  // },
  // mounted() {
  //   this.onResize();
  //   window.addEventListener("resize", this.onResize, { passive: true });
  // },
  // methods: {
  //   onResize() {
  //     this.isMobile = window.innerWidth < 992;
  //   },
  // },

};
</script>
<style>
a {
  text-decoration: none !important;
  color: #000 !important;
}
.nav-item1 {
  font-size: 23px;
}
.navbar-logo-left-container{
  padding: 0 !important;
}
.denuncias-item {
  height: 35px;
}
@media (min-width: 568px) {
  .denuncias-item {
    height: 20px;
  }
}
</style>
