<template>
  <div>
    <div class="container">
      <Header2/>
      <section style="margin-bottom: 100px; margin-top: 80px">
        <div class="mx-auto logos" style="max-width: 400px; padding: 0;">
            <div style="display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap;">
          <v-img
            :src="require('../../assets/images/logo.svg')"
            class="my-3"
            contain
            style="width:20%"
          />
          <v-img
            :src="require('../../assets/logo-arica.png')"
            class="my-3"
            contain
            style="width:5%"
          />
          <v-img
            :src="require('../../assets/logo-core.png')"
            class="my-3"
            contain
            style="width:25%"
          />
            </div>
        </div>
        <h2 class="Title">
          Resultado Encuesta y Sondeo Telefónico de Incivilidades de Seguridad
          en la Región de Arica y Parinacota
        </h2>
        <div class="container-content">
          <div
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b7e"
            style="
              -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
                rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
                rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              opacity: 1;
            "
            class="content-text"
          >
            <p
              data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
              style="opacity: 1"
              class="paragraph-8"
            >
              Respondieron 1.732 personas aceptando contestar la Encuesta, como
              la tendencia se mantuvo y con el ánimo de abarcar la mayor
              cantidad de datos posible, se logró una contestación de 8.242
              personas, la pregunta fue: “Buen día, lo estamos llamando de
              Fundación Valcar en base al Programa financiado por el GORE de
              AyP, el cual busca conocer en forma directa su apreciación sobre
              los diferentes delitos e incivilidades que se comenten en nuestra
              Región y que Usted ha podido ser testigo o apreciar en forma
              directa, o bien, que a Usted le generen un mayor grado de
              inseguridad?. Por favor marque la alternativa que a Usted lo
              representa?.”
            </p>
            <p style="font-weight: 800">Opciones</p>
            <ul class="paragraph-8">
              <li>Asalto a Mano Armada, Marque 1.</li>
              <li>Asalto en la vía Pública, Marque 2.</li>
              <li>Portonazos o Encerronas, Marque 3.</li>
              <li>Asalto en domicilio, Marque 4.</li>
              <li>Inmigración Irregular, Marque 5.</li>
              <li>Trafico de Drogas, Marque 6.</li>
              <li>Aumento de Homicidios, Marque 7.</li>
              <li>Violencia Intrafamiliar, Marque 8.</li>
              <li>Robo con Fuerza y con Violencia, Marque 9.</li>
              <li>Otros, Marque 0.</li>
            </ul>
            <br />
          </div>
          <div
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b85"
            style="opacity: 1"
            class="content-image"
          >
            <!--           <img
            src="@/assets/images/graficoseguridad.png"
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b86"
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 340.99261474609375px, 34vw"
            alt=""
            class="image-2"
          /> -->
            <div style="width: 450px">
              <PieChart2 />
            </div>
          </div>
        </div>

        <p
          data-w-id="45835889-a334-efa8-f1f2-05d3ba28c07a"
          style="opacity: 1"
          class="Sub"
        >
          Respondieron 924 personas escogiendo la Alternativa 1, o bien, la
          opción Asalto a Mano Armada , lo que representa un 11,2%, 954 personas
          escogiendo la Alternativa 2, o bien, la opción Asalto en la vía
          Pública con un 11,6%, 648 personas escogiendo la Alternativa 3, o
          bien, la opción Portonazos o Encerronas con un 7,9%, 703 personas
          escogiendo la Alternativa 4, o bien, la opción Asalto en Domicilio con
          un 8,5% de representatividad, 2.205 personas escogiendo la Alternativa
          5, o bien, la opción Inmigración Irregular con un 26,8% siendo esta la
          mas escogida por la población, 766 personas escogiendo la Alternativa
          6, o bien, la opción Trafico de Drogas con un 9,3%, 634 personas
          escogiendo la Alternativa 7, o bien, la opción Aumento de Homicidios
          con un 7,7%, 187 personas escogiendo la Alternativa 8, o bien, la
          opción Violencia Intrafamiliar con un 2,3%, 403 personas escogiendo la
          Alternativa 9, o bien, la opción Robo con Fuerza y con Violencia con
          un 4,9%, 818 personas escogiendo la Alternativa 0, o bien, la opción
          Otros como la incivilidad de Seguridad que les genera mayor grado de
          inseguridad, la cual representa un 9,9% de la votación total.
        </p>
        <!-- -->

        <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            margin-top: 50px;
          "
        >
          <h3>Georeferencia</h3>
          <div class="title-line"></div>
        </div>

        <!--         -->
        <div class="container-content">
          <div
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b7e"
            style="
              -webkit-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
                rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -moz-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1)
                rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
              -ms-transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              transform: translate3d(0, 40px, 0) scale3d(1, 1, 1) rotateX(0)
                rotateY(0) rotateZ(0) skew(0, 0);
              opacity: 1;
            "
            class=""
          >
            <img
              src="@/assets/images/mapaseguridad.png"
              data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b86"
              sizes="(max-width: 479px) 90vw, (max-width: 991px) 340.99261474609375px, 34vw"
              alt=""
              class="image-2"
            />
            <br />

            <div
              class="container"
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 30px;
              "
            >
              <h3>Resumen por sexo</h3>
              <div class="title-line"></div>
            </div>

            <p
              data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
              style="opacity: 1"
              class="parrafo2 container"
            >
              Respondieron 1.732 personas aceptando contestar la Encuesta. Del
              total, las Mujeres representan un 49,4% y los Hombres el 50,6%
              restante. En relación a los grupos etarios, tenemos que el grupo
              más representativo lo constituyen personas con edades mayores a 60
              años representando un 27%, seguido de las personas con edades
              entre los 40-49 años representando un 21,3% y en tercer lugar se
              ubica el Rango Etario de personas con edades entre los 50-59 años
              representando un 20,8%. En cuarto y quinto lugar se ubican los
              rangos etarios de personas con edades entre los 30-39 años, con
              una representación de un 19,2% y el rango etario de personas con
              edades ente los 18-29 años con un 11,6%. Estos valores se
              mantienen al pasar la Encuesta a un Sondeo Telefónico masivo en
              donde contestaron 8.242 personas. Cabe hacer notar que el estrato
              socioeconómico que más participación tuvo fue el C3 o Clase
              Media-Baja con un 17,1%, seguido del estrato D o Clase Baja con un
              15,2% y en tercer lugar se ubica el estrato C2 o Clase Media-Media
              con 14,9%.
            </p>

            <div class="container-items container">
              <div class="itemMan">
                <img src="@/assets/images/man.png" alt="" />
                <p class="percent">50.6%</p>
              </div>
              <div class="itemWoman">
                <img src="@/assets/images/woman.png" alt="" />
                <p class="percent">49.4%</p>
              </div>
            </div>

            <div
              class="container"
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 50px;
              "
            >
              <h3>Tramo por edad</h3>
              <div class="title-line"></div>
            </div>

            <div class="percent-container container" style="margin-top: 80px">
              <div class="percent-item">
                <p class="percent-1">11.4%</p>
                <p class="percent-2">939</p>
                <p class="percent-3">18-29</p>
              </div>
              <div class="percent-item">
                <p class="percent-1">19%</p>
                <p class="percent-2">1568</p>
                <p class="percent-3">30-39</p>
              </div>
              <div class="percent-item">
                <p class="percent-1">21.2%</p>
                <p class="percent-2">1745</p>
                <p class="percent-3">40-49</p>
              </div>
              <div class="percent-item">
                <p class="percent-1">21%</p>
                <p class="percent-2">1729</p>
                <p class="percent-3">50-49</p>
              </div>
              <div class="percent-item">
                <p class="percent-1">27.4%</p>
                <p class="percent-2">2261</p>
                <p class="percent-3">60+</p>
              </div>
            </div>

            <div
              class="container"
              style="
                display: flex;
                width: 100%;
                align-items: center;
                margin-top: 50px;
              "
            >
              <h3>Total por opciones</h3>
              <div class="title-line"></div>
            </div>

            <v-simple-table style="margin-top: 30px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                      style="font-weight: 800; color: #000; font-size: 16px"
                    >
                      Opciones
                    </th>
                    <th
                      class="text-center"
                      style="font-weight: 800; color: #000; font-size: 16px"
                    >
                      Cantidad respuesta
                    </th>
                    <th
                      class="text-center"
                      style="font-weight: 800; color: #000; font-size: 16px"
                    >
                      %
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tablaSeguridad" :key="item.opciones">
                    <td style="font-weight: 500">{{ item.opciones }}</td>
                    <td
                      style="
                        font-size: 22px;
                        color: #007fff;
                        text-align: center;
                      "
                    >
                      {{ item.respuestas }}
                    </td>
                    <td
                      style="
                        font-size: 30px;
                        font-weight: 800;
                        color: #007fff;

                        display: flex;
                        justify-content: center;
                      "
                    >
                      {{ item.porcentaje }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <!--         <div
          data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b85"
          style="opacity: 1"
          class="content-image"
        >
          <img
            src="@/assets/images/Sexo_edad_incivilidades.jpg"
            style="opacity: 1; width: 100%"
            data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b86"
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 340.99261474609375px, 34vw"
            alt=""
            class="image-2"
          />
        </div> -->
        </div>

      <div style="width: 100%; display: flex; justify-content: center; margin-top: 92px;">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/e5Z6maior9Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

        <div style="margin-top: 100px">
          <h2 class="Title">Encuesta sobre Inmigración con Grabación</h2>

          <p class="paragraph-8" style="margin-top: 50px;">
            La pregunta que se realizo por vía telefónica fue la siguiente: “Buen
            día, lo llamamos de parte de Fundación Valcar para conocer su
            opinión sobre como arreglaría Usted el principal problema de
            Seguridad de la Región, que según la encuesta que ya realizamos en
            el mes de Marzo pasado, era la inmigración irregular. Si usted no
            desea contestar solo cuelgue por favor y ahora si desea dar su
            opinión, al momento de la señal, Usted dispondrá de 30 segundos para
            dejarnos grabada su solución?.”
          </p>

          <h3 style="margin-top:50px;">Muestra de transcripción de las grabaciones.</h3>
          <p class="parrafo2">La siguiente tabla, corresponde a una muestra de la transcripción de las grabaciones proporcionadas por los ciudadanos de Arica y Parinacota.</p> 
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left">
            Fecha
          </th>
          <th class="text-left">
            Transcripción
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in transcripciones"
          :key="item.A"
        >
          <td>{{ item.B }}</td>
          <td>{{ item.A }}</td>
        </tr>
      </tbody>
  </v-simple-table>

          <h3 style="margin-top: 50px;">Conclusiones de la transcripción de las grabaciones.</h3>
          <p
              data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
              style="opacity: 1"
              class="paragraph-8 mt-3"
            >
            La llamada que se realizó parece tener respuestas muy diversas y en ocasiones incoherentes. A continuación, se presenta un resumen general de las opiniones expresadas:
            </p>
            <ul class="paragraph-8">
              <li>Algunas personas consideran que todos somos emigrantes y que los países deben ser respetuosos y acoger a los migrantes, pero sin permitir actividades delictivas.</li>
              <li>Otros mencionan la falta de control y facilidad para obtener documentos legales, lo que permite la entrada de delincuentes.</li>
              <li>Algunas personas expresan que no pueden opinar sobre el tema porque no conocen el sistema o no son los responsables.</li>
              <li>Algunas respuestas se enfocan en la delincuencia y piden medidas más estrictas, como la presencia militar, mayor control policial, informar sobre los lugares de venta de drogas y uso de armas de fuego.</li>
              <li>También se mencionan propuestas como mejorar la iluminación, realizar patrullajes, fortalecer las leyes y sanciones, controlar la migración irregular y dar mayor responsabilidad a los padres.</li>
              <li>Algunas respuestas son insultantes y expresan frustración y descontento con las autoridades.</li>
            </ul>
                    <p
              data-w-id="88086931-0bbf-a2f5-1fc3-58d79ba19b83"
              style="opacity: 1"
              class="paragraph-8"
            >
            En general, parece haber preocupación por la seguridad y la migración irregular, así como la necesidad de implementar medidas más estrictas y mejorar el sistema de control.
            </p>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header2 from "../commons/Header2.vue";
import Footer from "../commons/Footer.vue";
import PieChart2 from "../charts/PieChart2.vue";

export default {
  components: {
    Header2,
    Footer,
    PieChart2,
  },
  data() {
    return {
      tablaSalud: [
        {
          opciones: "Hipertensión Arterial,Marque 1.",
          respuestas: "1274",
          porcentaje: "31.8%",
        },
        {
          opciones: "Diabetes, Marque 2.",
          respuestas: "798",
          porcentaje: "19.9%",
        },
        {
          opciones: "Obesidad, Marque 3.",
          respuestas: "412",
          porcentaje: "10.3%",
        },
        {
          opciones: "Todas Las Anteriores, Marque 4.",
          respuestas: "521",
          porcentaje: "13%",
        },
        {
          opciones: "Otras, Marque 5.",
          respuestas: "1006",
          porcentaje: "25.1%",
        },
        { opciones: "Total", respuestas: "4011", porcentaje: "100%" },
      ],
      tablaSeguridad: [
        {
          opciones: "Asalto a Mano Armada, Marque 1.",
          respuestas: "924",
          porcentaje: "11.2%",
        },
        {
          opciones: "Asalto en la vía Pública, Marque 2.",
          respuestas: "954",
          porcentaje: "11.6%",
        },
        {
          opciones: "Portonazos o Encerronas, Marque 3.",
          respuestas: "648",
          porcentaje: "7.9%",
        },
        {
          opciones: " Asalto en domicilio, Marque 4.",
          respuestas: "703",
          porcentaje: "8.5%",
        },
        {
          opciones: "Inmigración Irregular, Marque 5.",
          respuestas: "2205",
          porcentaje: "26.8%",
        },
        {
          opciones: "Trafico de Drogas, Marque 6.",
          respuestas: "766",
          porcentaje: "9.3%",
        },
        {
          opciones: "Aumento de Homicidios, Marque 7.",
          respuestas: "634",
          porcentaje: "7.7%",
        },
        {
          opciones: "Violencia Intrafamiliar, Marque 8.",
          respuestas: "187",
          porcentaje: "2.3%",
        },
        {
          opciones: "Robo con Fuerza y con Violencia, Marque 9.",
          respuestas: "403",
          porcentaje: "4.9%",
        },
        {
          opciones: "Otros, Marque 0.",
          respuestas: "818",
          porcentaje: "9.9%",
        },
        { opciones: "Total", respuestas: "8242", porcentaje: "100%" },
      ],
      transcripciones: [
        {
   "A": "Los robos, los robos, los asaltos, sobre todo en las calles centrales, por el abandono que hay y la poca luminosidad, la poca luminaria, y eso también depende mucho del municipio.",
   "B": "2023-06-28 12:01:57"
 },
 {
   "A": "La delincuencia narica. Eso es lo que más perjudica a Canarica, la delincuencia.",
   "B": "2023-06-28 11:52:46"
 },
 {
   "A": "¿Es más delincuente que la policía en San José o más delincuente?",
   "B": "2023-06-28 11:54:17"
 },
 {
   "A": "Creo que el delito, los robos, tratan de entrarse a las casas. Hoy día fui al gimnasio y en el estadio me han matado a cuchillazo a un señor. Sí es la delincuencia, eso es el problema. Es la delincuencia y los robos. Bueno, la delincuencia es robo, pero los asaltos. A otro amigo le han quitado la cartera y salieron arrancando. No puede ser. Tanto desastre con esta gente que hay aquí.",
   "B": "2023-06-28 13:23:42"
 },
 {
   "A": "No, yo lo encuentro excelente que hagan ese tipo de proyectos.",
   "B": "2023-06-28 12:02:50"
 },
 {
   "A": "Hola, buenas, en realidad yo creo que la migración sigue igual. Acá en Arica cada vez estamos peor, lleno de gente, no toda es mala, pero generalmente son malos. Están los cerros poblados, todos los cerros que hay en Arica están poblados de inmigrantes, de todas las nacionalidades. Gracias.",
   "B": "2023-06-28 12:08:17"
 },
 {
   "A": "Los que andan robando son más los que son vehículos acá, en Arica, los extranjeros tienen la tenda de laca, roban y roban vehículos. Anoche mismo estaban robando en la vía de Pedro Lago, en Ginebra. Todos los días roban en Ginebra. Así que estamos aburridos, estamos haciendo un conglomerado por WhatsApp para salir a agarrar los apalos delincuentes, porque uno llama carabineros y no llegan nunca. He dicho caso cerrado.",
   "B": "2023-06-28 13:24:06"
 },
 {
   "A": "Sigue siendo el mismo, porque ya hay muchos que están radicados acá y no hacen nada, gente joven que sigue llegando, sigue entrando, ese es el problema. Yo en la mañana vi como cuatro con mochilas venían llegando. Sigue siendo lo mismo, no como antes pero siguen entrando.",
   "B": "2023-06-28 13:24:29"
 },
 {
   "A": "La delincuencia y robos continuos, eso es lo que siempre se ve, y yo tengo cámara de seguridad en mi casa y veo como roban los autos a otras casas, que los vecinos me vienen a preguntar si les puede pasar la grabación, porque siempre hay robos, a un vecino le roban hasta el auto, se lo llevaron completo, entonces, no sé, eso creo que no se ha controlado todavía.",
   "B": "2023-06-28 13:25:00"
 },
 {
   "A": "No me llamen más, no me interesa. Todos los políticos son unos ladrones de mierda, así que no me llamen, no pierdan el tiempo. Hasta luego, que les vaya bien.",
   "B": "2023-06-28 12:09:41"
 },
 {
   "A": "La delincuencia y la migración son las dos cosas más preocupantes. Las dos cosas a mí son las más preocupantes en estos momentos, fuera de otras tantas más que hay, pero esas son las que deben darse la prioridad.",
   "B": "2023-06-28 12:07:19"
 },
      ]
    };
  },
      mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "/js/webflow.js");
    plugin.setAttribute("type", "text/javascript");
    document.head.appendChild(plugin);
    //
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62f4510e73201624361551ab"
    );
    plugin2.setAttribute(
      "integrity",
      "sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    );
    plugin2.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(plugin2);
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 90%;
}
@media (max-width: 568px) {
  .container {
    width: 100%;
  }
}
.Title {
  font-size: 22px;
  display: flex;
  justify-content: center;
  color: #000;
  background: rgb(1, 91, 254);
  background: linear-gradient(
    90deg,
    rgba(1, 91, 254, 1) 0%,
    rgba(65, 234, 216, 1) 100%
  );
  color: #fff;
  padding: 30px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 23px;
}
.Sub {
  margin: 0 auto;
  margin-top: 50px;
  background-color: rgb(1, 91, 254);
  color: #fff;
  padding: 25px;
  width: fit-content;
  display: flex;
  border-radius: 23px;
  max-width: 1200px;
  font-weight: 500;
  text-align: justify;
}
@media (max-width: 568px) {
  .Sub {
    width: 96vw;
  }
}

h3 {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 800;
  margin: 0;
}

.title-line {
  width: 100%;
  height: 9px;
  background: linear-gradient(
    90deg,
    rgba(1, 91, 254, 1) 0%,
    rgba(65, 234, 216, 1) 100%
  );
  border-radius: 25px;
  margin-left: 20px;
}
.parrafo2 {
  color: #707070;
  text-align: justify;
  margin-top: 30px;
}
/* @media (max-width: 568px) {
  .parrafo2 {
    width: 91vw;
  }
}
 */

//resumen por sexo
.container-items {
  display: flex;
  justify-content: space-between;
  gap: 80px;
}
@media (max-width: 568px) {
  .container-items {
    flex-wrap: wrap;
    gap: 60px;
  }
}

.itemMan {
  background: transparent linear-gradient(111deg, #0058ff 0%, #41ebd8 100%) 0%
    0% no-repeat padding-box;
  border-radius: 33px;
  width: 50%;
  padding: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 568px) {
  .itemMan {
    width: 100%;
  }
}
.itemWoman {
  padding: 15px;
  background: transparent linear-gradient(252deg, #ff00ba 0%, #de41eb 100%) 0%
    0% no-repeat padding-box;
  border-radius: 33px;
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 568px) {
  .itemWoman {
    width: 100%;
  }
}

.percent {
  font-size: 50px;
  font-weight: 800;
  color: #fff;
  display: flex;
}

//tramo por edad

.percent-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.percent-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #e4e4e4;
}

.percent-1 {
  color: #007fff;
  font-size: 32px;
  font-weight: 800;
}
.percent-2 {
  font-size: 26px;
}
.percent-3 {
  font-size: 20px;
  font-weight: 800;
}
@media (max-width: 568px) {
  .percent-1 {
    color: #007fff;
    font-size: 22px;
    font-weight: 800;
  }
  .percent-2 {
    font-size: 18px;
  }
  .percent-3 {
    font-size: 18px;
    font-weight: 800;
  }
}

.container-content {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}
@media (max-width: 768px) {
  .container-content {
    flex-direction: column;
  }
  .content-text {
    width: 90vw !important;
  }
  .content-image {
    width: 90vw !important;
  }
}
.container-content-reverse {
  margin: 0 auto;
  margin-top: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}
.content-text {
  width: 50%;
  max-width: 500px;
  text-align: justify;
}
.content-image {
  justify-content: center;
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .container-content-reverse {
    flex-direction: column;
    width: 100vw;
  }
}

.image-2 {
  width: 100%;
}

@media (min-width: 768px) {
  .image-2 {
    width: 70%;
    display: flex;
    margin: 0 auto;
  }
}
</style>
