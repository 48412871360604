<template>
  <section class="footer-dark wf-section">
    <div class="container-5">
      <div class="footer-wrapper">
        <a href="#" class="footer-brand w-inline-block"><img src="../../assets/images/logo.svg" loading="lazy" alt="" class="image-8"></a>
        <div class="footer-content">
          <div id="w-node-_9bac101c-b527-bdea-2d4a-6f8bcc6fdc6c-8b71d0af" class="footer-block">
            <div class="title-small">Seguridad</div>
            <router-link style="margin-left: 0; padding-left: 0; font-size:18px;" :to="{ path: '/', hash: 'seguridad' }" class="">Programas de Seguridad</router-link>
            <router-link style="margin-left: 0; padding-left: 0;font-size: 18px; margin-top:4px;" :to="{ path: '/', hash: 'deportes' }" class="">Programas Deportivos</router-link>
            <router-link  :to="{ path: '/ganadores-camaras'}" style="color: #1a1b1f; font-size: 18px; margin-top:4px;" >Sorteos</router-link>
          </div>
          <div id="w-node-_9bac101c-b527-bdea-2d4a-6f8bcc6fdc77-8b71d0af" class="footer-block">
            <div class="title-small">Salud</div>
            <router-link style="margin-left: 0; padding-left: 0;font-size:18px;" :to="{ path: '/', hash: 'salud' }" class="">Programas de Salud</router-link>
            <div class="footer-social-block">
              <a href="#" class="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ac15112aad5_twitter%20small.svg" loading="lazy" alt=""></a>
              <a href="#" class="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a389912aad8_linkedin%20small.svg" loading="lazy" alt=""></a>
              <a href="#" class="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a51bf12aae9_facebook%20small.svg" loading="lazy" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-divider"></div>
    <div class="footer-copyright-center">Todos los derechos reservados © 2023 Fundación Valcar</div>
  </section>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>

a {
  text-decoration: none;
  color: #fff;
}
</style>
