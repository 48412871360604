import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/index.vue'
import PreguntasFrecuentes from '../components/PreguntasFrecuentes/index.vue'
import Checkout from '@/components/checkout/index.vue'
import EncuestaSalud from '@/components/Encuestas/EncuestaSalud.vue'
import EncuestaIncivilidades from '@/components/Encuestas/EncuestaIncivilidades.vue'
import GaleriaRegion from '@/components/Galeria/GaleriaRegion.vue'
import GaleriaIduam from '@/components/Galeria/GaleriaIduam.vue'
import DenunciasDestacadas from '@/components/DenunciasDestacadas/DenunciasDestacadas.vue'
import Nosotros from '@/components/Nosotros/Nosotros.vue'
import Videos from '@/components/Videos/Videos.vue'
import ConcourseResults from '@/components/ConcourseResults/ConcourseResults.vue'
import Testimoniales from '@/components/Testimoniales/Testimoniales.vue'
import TestimonialesUsuarios from '@/components/Testimoniales/TestimonialesUsuarios.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/encuestas/salud',
    name: 'EncuestaSalud',
    component: EncuestaSalud
  },
  {
    path: '/encuestas/incivilidades',
    name: 'EncuestaIncivilidades',
    component: EncuestaIncivilidades
  },
  {
    path: '/lanzamiento-regionconectada',
    name: 'GaleriaRegion',
    component: GaleriaRegion
  },
  {
    path: '/lanzamiento-iduam',
    name: 'GaleriaIduam',
    component: GaleriaIduam
  },
  {
    path: '/denuncias-destacadas',
    name: 'DenunciasDestacadas',
    component: DenunciasDestacadas
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: Nosotros
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/ganadores-camaras',
    name: 'ConcourseResults',
    component: ConcourseResults
  },
  {
    path: '/testimoniales',
    name: 'Testimoniales',
    component: Testimoniales
  },
  {
    path: '/iduam-videos',
    name: 'TestimonialesUsuarios',
    component: TestimonialesUsuarios
  },
  // {
  //   path: '/preguntas_frecuentes',
  //   name: 'PreguntasFrecuentes',
  //   component: PreguntasFrecuentes
  // },
  // {
  //   path: '/Checkout',
  //   name: 'Checkout',
  //   component: Checkout
  // }

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
