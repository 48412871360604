<template>
  <main style="min-height: 100vh; position: relative; padding-top: 20px;">
    <Header2 />
    <h2 class="text-center" style="margin-top: 100px;">Resultados Sorteo de Cámaras</h2>
    <p class="text-center" style="color: #000;">Ganadores de las primeras 20 cámaras. 11/28/2023</p>

    <div style="display:flex; justify-content: center; gap: 20px; margin: 0 auto; flex-wrap: wrap;">
            <img
            style="object-fit: cover; width: 150px;"
              src="../../assets/images/testimoniales/19.jpeg"
            />  
            <img
            style="object-fit: cover; width: 150px; height; 100px;"
              src="../../assets/images/testimoniales/20.jpeg"
            />  
    <img style="width: 200px;height: 250px; align-self: center;" src="../../assets/soscam.png" alt="Cámara">
    </div>

    <v-simple-table class="elevation-4" style="max-width: 600px; margin: 0 auto; margin-top: 12px; margin-bottom: 100px;">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Nombre</th>
            <th class="text-left">Teléfono</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in winners" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.phone }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

        <p class="text-center" style="color: #000;">Ganadores 2° sorteo 30 cámaras. 17/01/2024</p>

      <v-simple-table class="elevation-4" style="max-width: 600px; margin: 0 auto; margin-top: 12px; margin-bottom: 100px;">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Nombre</th>
            <th class="text-left">Teléfono</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in winners2" :key="item.name">
            <td>{{ item.name }}</td>
            <td style="text-transform: uppercase;">{{ item.phone }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

              <div class="mx-auto logos" style="max-width: 400px; padding: 20px;">
            <div style="display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap;">
          <v-img
            :src="require('../../assets/images/logo.svg')"
            class="my-3"
            contain
            style="width:20%"
          />
          <v-img
            :src="require('../../assets/logo-arica.png')"
            class="my-3"
            contain
            style="width:5%"
          />
          <v-img
            :src="require('../../assets/logo-core.png')"
            class="my-3"
            contain
            style="width:25%"
          />
            </div>
        </div>
    <p style="text-align: center; font-size: 18px;">Nos comunicaremos por email o whatsapp con los ganadores para coordinar la entrega de las cámaras.</p>
    <Footer />
  </main>
</template>

<script>
import Header2 from '../commons/Header2.vue'
import Footer from '../commons/Footer.vue'
export default {
    components: {
    Header2,
    Footer
    },
    data() {
        return {
            winners: [
                {
                name: 'Mario Araya',
                phone: 'XXXXXX472'
                },
                {
                    name: 'Paola Rosa Rojas Méndez',
                    phone: 'XXXXXX818'
                },
                {
                    name: 'Zoraida González',
                    phone: 'XXXXXX201'
                },
                {
                    name: 'Jose Quintana',
                    phone: 'XXXXXX971'
                },
                {
                    name: 'Alejandra Karina Santander Minio',
                    phone: 'XXXXXX183'
                },
                {
                    name: 'Moises Rojas',
                    phone: 'XXXXXX365'
                },
                {
                    name: 'Bernardo Antonio Ponce Sanchez',
                    phone: 'XXXXXX001'
                },
                {
                    name: 'Ximena Cisternas',
                    phone: 'XXXXXX191'
                },
                {
                    name: 'Oscar Villca',
                    phone: 'XXXXXX615'
                },
                {
                    name: 'Maria Valdebenito',
                    phone: 'XXXXXX058'
                },
                {
                    name: 'Adolfo Bernales',
                    phone: 'XXXXXX992'
                },
                {
                    name: 'Nelson Gonzalez',
                    phone: 'XXXXXX582'
                },
                {
                    name: 'Elizabeth Farias Clavero',
                    phone: 'XXXXXX674'
                },
                {
                    name: 'Daniel Lopez Vargas',
                    phone: 'XXXXXX093'
                },
                {
                    name: 'Roxana Villar',
                    phone: 'XXXXXX082'
                },
                {
                    name: 'Ivonne Vargas Medel',
                    phone: 'XXXXXX660'
                },
                {
                    name: 'Bárbara Calvo Ruiz',
                    phone: 'XXXXXX474'
                },
                {
                    name: 'Anibal Alejandro Cofré Morales',
                    phone: 'XXXXXX113'
                },
                {
                    name: 'Gabriel Antonio Castillo Encina',
                    phone: 'XXXXXX997'
                },
                {
                    name: 'Monserrat Sagre',
                    phone: 'XXXXXX756'
                },
        ],
            winners2: [
          {
           name: "AEDO NAVARRETE MANUEL ALEJANDRO",
           phone: "xxxxxx562"
          },
          {
           name: "AGUILERA AGUILERA ROSA ELENA",
           phone: "xxxxxx649"
          },
          {
           name: "ALBORNOZ MIRANDA JOSE LUIS",
           phone: "xxxxxx756"
          },
          {
           name: "ALVARADO ZELADA IRIS PAMELA",
           phone: "xxxxxx305"
          },
          {
           name: "ANGIELINNA SAGREDO",
           phone: "xxxxxx990"
          },
          {
           name: "ARAYA MESINA VANESSA ELIZABETH",
           phone: "xxxxxx304"
          },
          {
           name: "BARRAZA MANDIOLA ALEJANDRO BERNARDO",
           phone: "xxxxxx162"
          },
          {
           name: "BENJAMIN IGNACIO BERRIOS BARRIOS",
           phone: "xxxxxx513"
          },
          {
           name: "BOCAZ MARTINEZ JOSE MARCOS",
           phone: "xxxxxx935"
          },
          {
           name: "CONDORE MORENO OLGA ESTER",
           phone: "xxxxxx308"
          },
          {
           name: "DANIEL ZARATE MUÑOZ",
           phone: "xxxxxx309"
          },
          {
           name: "EDSON CASTILLO",
           phone: "xxxxxx722"
          },
          {
           name: "EDUARDO ALFONSO VILLARROEL RAMIREZ",
           phone: "xxxxxx414"
          },
          {
           name: "EDUARDO ISAIAS MORIS NAVARRO",
           phone: "xxxxxx842"
          },
          {
           name: "ELIEN DEL ROSARIO CARRASCO TRUJILLO",
           phone: "xxxxxx801"
          },
          {
           name: "ESTEFANIE GODOY",
           phone: "xxxxxx182"
          },
          {
           name: "ESTER JELDES SALINAS",
           phone: "xxxxxx786"
          },
          {
           name: "FRANCISCO JAVIER MALDONADO PINO",
           phone: "xxxxxx846"
          },
          {
           name: "GUILLERMO BARRAZA",
           phone: "xxxxxx565"
          },
          {
           name: "JARA INOSTROZA RODRIGO IGNACIO",
           phone: "xxxxxx885"
          },
          {
           name: "JOSÉ LUIS VARGAS PAEZ",
           phone: "xxxxxx716"
          },
          {
           name: "JUAN MOYA",
           phone: "xxxxxx417"
          },
          {
           name: "MICHILENO CASTRO NUBIA",
           phone: "xxxxxx779"
          },
          {
           name: "MONDACA MONDACA SARA VIVIANA",
           phone: "xxxxxx940"
          },
          {
           name: "NORAMBUENA ROMERO INES DEL ROSARIO",
           phone: "xxxxxx427"
          },
          {
           name: "OVALLE FREDES MARICEL ALEJANDRA",
           phone: "xxxxxx242"
          },
          {
           name: "PALACIOS MOLINA MARIA ESTELA",
           phone: "xxxxxx726"
          },
          {
           name: "TORO ESCOBAR MIGUEL ANGEL",
           phone: "xxxxxx204"
          },
          {
           name: "VICTOR MANUEL CÉSPEDES ORTIZ",
           phone: "xxxxxx049"
          },
          {
           name: "VILLARROEL BAHAMONDE JUAN AMERICO",
           phone: "xxxxxx549"
          }
    ]
    }
    },
    mounted() {


            const plugin = document.createElement("script");
    plugin.setAttribute("src", "/js/webflow.js");
    plugin.setAttribute("type", "text/javascript");
    document.head.appendChild(plugin);
    //
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62f4510e73201624361551ab"
    );
    plugin2.setAttribute(
      "integrity",
      "sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    );
    plugin2.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(plugin2);
    },
};
</script>

<style lang="scss" scoped>
</style>
