<template>
  <main>
    <Header2 />
    <section class="Nosotros container">
        <h1>Nosotros</h1>
      <p class="paragraph-8">
        Fundación Valcar es una organización sin fines de lucro, creada en el
        año 2019, que tiene como objetivo general el desarrollo de iniciativas
        que permitan ayudar a mejorar las políticas públicas en las áreas del
        Deporte, la Salud y la Seguridad Pública, principalmente a través del
        análisis de información recopilada masivamente a través de la
        interacción que generen los propios habitantes de un territorio con
        Sistemas Tecnológicos de Vanguardia que, a su vez, al aportar beneficios
        de diversa naturaleza para ellos, incentivan su uso. En otras palabras,
        trabajamos para pesquisar necesidades de la población que podamos
        satisfacer con tecnología de punta y que estas herramientas, al mismo
        tiempo, nos entreguen información valiosa para colaborar con el
        desarrollo de políticas públicas focalizadas y de mejor calidad.
      </p>
      <p>
        El Deporte, la Salud, la Seguridad Pública, el desarrollo de Tecnologías
        de Vanguardia y el Análisis Estadístico son áreas del conocimiento que
        están íntimamente vinculadas a la historia de vida de algunos de
        nuestros fundadores y que explican el nacimiento y evolución de
        Fundación Valcar.
      </p>
      <p>
        Dos de nuestros fundadores están fuertemente vinculados al deporte:
        Carlos Valenzuela Silva fue un destacado futbolista durante su juventud
        principalmente en Selecciones Universitarias ; y Carlos Valenzuela Neut
        fue un sobresaliente y talentoso tenista, llegando a alcanzar el Primer
        Lugar del Ranking de la Categoría Menores de 14 años de la Federación de
        Tenis de Chile (<a target="_blank" href="http://www.carlosvalenzuelaneut.com">Ver http://www.carlosvalenzuelaneut.com</a>). Sin lugar a
        dudas, el deporte dejó una potente huella en sus vidas y, más allá de
        los conocidos beneficios fisiológicos que aporta, los valores que esta
        actividad entrega, como la amistad, el compromiso, el trabajo en equipo,
        la disciplina, la inclusión, la perseverancia y el respeto, son
        principios que lograron fortalecer gracias a su práctica constante.
      </p>
      <p>
        Dar siempre lo mejor de cada uno e intentar superarse permanentemente es
        el desafío de todo deportista de alto rendimiento, pero es sabido que
        esta actitud es trasladable a todas las actividades de la vida y puede
        significar cambios potentes, positivos y permanentes en todos y cada uno
        de nosotros. Es así que conociendo estos fundadores en primera persona
        lo valioso de la actividad deportiva, tanto desde una perspectiva
        funcional como valórica, es que con el ánimo de incentivar el deporte
        nace nuestra organización en marzo de 2019 como Fundación Deportiva
        Valcar, teniendo como principales objetivos la promoción, difusión,
        desarrollo y perfeccionamiento del deporte.
      </p>
      <p>
        En julio de 2022, nuestra Fundación amplía su objeto a las áreas de
        Seguridad y de Salud, entendiendo que la actividad y desarrollo del
        deporte se vincula con un ambiente de vida saludable, seguro, dotado de
        condiciones de vida de la ciudad en un entorno de seguridad pública y a
        propósito del amplio conocimiento y vital experiencia del fundador
        Carlos Valenzuela Silva en tecnologías de vanguardia aplicadas a estas
        materias.  <br><a target="_blank" href="./CERTIFICADO_AUTORIZACIÓN_MINISTERIO_AMPLIACION_OBJETO_Fundación Deportiva VALCAR.pdf">Ver Objeto. </a> <br>
        <a target="_blank" href="./REDUCCION A ESCRITURA PUBLICA_AMPLIACION_OBJETO.pdf"> Ver Ampliación del Objeto.</a> <br>
        <a target="_blank" href="./CERTIFICADO_AUTORIZACIÓN_MINISTERIO_AMPLIACION_OBJETO_Fundación Deportiva VALCAR.pdf"> Ver Certificado Autorización Ministerio.</a> <br>
        <a target="_blank" href="./CERTIFICADO_MUNICIPIO_DANDO_EL_OK_A_LA_AMPLIACIÓN_DEL_OBJETO.pdf"> Ver Certificado Municipal.</a> <br>
        <a target="_blank" href="./CERTIFICADO_REGISTRO_CIVIL - RPJ 290534- REFORMA -LO BARNECHEA.pdf"> Ver Certificado Registro Civil.</a> <br>
      </p>
      <p>
        Debemos destacar que Carlos Valenzuela Silva, luego de su experiencia
        deportiva, se tituló de Ingeniero Comercial mención Administración y
        Licenciado en Ciencias Económicas y Administrativas de la Universidad de
        Chile, además estudió las carreras de informática y técnico estadístico, ésta última en la Pontificia Univiersidad Católica de Chile. Desde la década de los 80 viene desarrollando o
        participando en emprendimientos relacionados a tecnologías de
        vanguardia. Dentro de sus trabajos más destacados se encuentran: En 1985
        trabajó en la puesta en marcha del Sistema de Cajeros Automáticos, que
        posteriormente se transformó en Redbanc al ser la contrapartida como
        funcionario de la SBIF; A partir del año 1991, ya de forma
        independiente, desarrolla la “Automatización de Proyectos Bancables de
        las Pymes”, a través del Subsidio SUAF otorgado por CORFO; A partir de
        1996 creó y desarrolló los
        <a target="_blank" href="./CATALOGO DE KIOSCOS VIRTUALES.pdf">“Sistemas de Kioscos de Autoconsulta Interactiva”</a> para
        empresas como CTC (actual Movistar) y diferentes centros comerciales
        capitalinos, tecnología que en esa época marcó un gran hito en el inicio
        de la automatización de servicios dirigidos a los clientes.
      </p>
      <p>
        Posteriormente, ya con empresas propias, a partir de 1998 desarrolla
        principalmente innovadoras Plataformas de Comunicación, tales como:
        Sistema Vitral (semejante al Sistema Skype); Sistema de Call Center
        Telefónico descentralizado; Click2Call y Aplicaciones Móviles en las
        Áreas laboral (<span style="font-weight:800;">Su Pega</span>), Salud (<span style="font-weight:800;">Iduam</span>) y de Seguridad (<span style="font-weight:800;">SOSClICK;
        SOSDenuncias; SOSClickMujer; Comuna Conectada y Región Conectada</span>).
      </p>
      <p>
        En 4 febrero de 2016, logró registrar a su nombre la propiedad
        intelectual de <span style="font-weight:800;">“SISTEMA DE ATENCIÓN A TRAVÉS DE VIDEO CONFERENCIA O
        CLICK2CALL”</span>. (<a target="_blank" href="./CERTIFICADO_SISTEMA_DE_ATENCION_VIDEOCONFERENCIA_CLICK2CALL.pdf">Ver Certificado Propiedad Intelectual</a>)
      </p>
      <p>
        En febrero de 2018, logró registrar a su nombre la propiedad intelectual
        de <span style="font-weight:800;">“IDUAM PROYECTO DE MEDICIÓN DE PARÁMETROS DE SALUD A DISTANCIA”</span>.
        <a target="_blank" href="./CERTIFICADO_INSCRIPCION_IDUAM.pdf">Ver Certificado Propiedad Intelectual)</a>
      </p>
      <p>
        Luego, en mayo del 2018, registró a su nombre la propiedad intelectual
        del <span style="font-weight:800;">“SISTEMA COMUNA CONECTADA”</span>.
        <a target="_blank" href="./CERTIFICADO_TECNOLOGIA_UNICA_COMUNA_CONECTADA.pdf">(Ver Certificado Propiedad Intelectual)</a>
      </p>
      <p>
        Luego, en marzo de 2020, registró a su nombre la propiedad intelectual
        del <span style="font-weight:800;">“SISTEMA DE MONITOREO Y VIGILANCIA: SOSCLICK”</span>.
        <a target="_blank" href="./CERTIFICADO_DIBAM_SOSCLICK.pdf">(Ver Certificado Propiedad Intelectual)</a>
      </p>
      <p>
        Actualmente en <span style="font-weight:800;">Fundación Valcar</span> continuamos trabajando altamente
        comprometidos para que a través del desarrollo de tecnologías de
        vanguardia e iniciativas innovadoras en las áreas del Deporte, Salud y
        Seguridad Pública se logre mejorar la calidad de vida de nuestros
        compatriotas.
      </p>
    </section>

    <Footer />
  </main>
</template>

<script>
import Header2 from "../commons/Header2.vue";
import Footer from "../commons/Footer.vue";
export default {
  components: { Header2, Footer },
  data() {
    return {};
  },
    mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "/js/webflow.js");
    plugin.setAttribute("type", "text/javascript");
    document.head.appendChild(plugin);
    //
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62f4510e73201624361551ab"
    );
    plugin2.setAttribute(
      "integrity",
      "sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    );
    plugin2.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(plugin2);
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/normalize.css';
@import '../../assets/css/webflow.css';
@import '../../assets/css/valcar.webflow.css';

.Nosotros {
  margin-top: 120px;
  margin-bottom: 50px;
  text-align: justify;
}
a {
    color: rgb(20, 20, 248) !important;
}
</style>
