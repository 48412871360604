<template>
  <div>
    <v-app-bar fixed height="100">
      <div class="Navbar">
        <div class="Navbar__Content">
          <router-link :to="{ path: '/' }">
            <v-img
              contain
              lazy-src="../../assets/images/logo.svg"
              max-height="184"
              max-width="262"
              src="../../assets/images/logo.svg"
            ></v-img>
          </router-link>

          <ul class="Navbar__Content__List">
            <li class="Navbar__Content__List__Item">
              <router-link :to="{ path: '/nosotros' }">Nosotros</router-link>
            </li>
            <li class="Navbar__Content__List__Item"><router-link :to="{ path: '/videos' }">Videos</router-link></li>
            <li class="Navbar__Content__List__Item">
              <v-menu offset-y style="z-index: 34242">
                <template v-slot:activator="{ on, attrs }">
                  <li
                    class="Navbar__Content__List__Item"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Encuestas
                  </li>
                </template>
                <v-list>
                  <v-list-item>
                    <router-link
                      style="width: 100%"
                      :to="{ path: '/encuestas/incivilidades' }"
                      >Incivilidades</router-link>
                  </v-list-item>
                  <v-list-item>
                    <router-link
                      style="width: 100%"
                      :to="{ path: '/encuestas/salud' }"
                      >Salud</router-link
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </li>
            <li class="Navbar__Content__List__Item">
              <router-link
                :to="{ path: '/', hash: 'seguridad' }"
                >Programas de Seguridad</router-link
              >
            </li>
            <li class="Navbar__Content__List__Item">
              <router-link :to="{ path: '/', hash: 'salud' }"
                >Programas de Salud</router-link
              >
            </li>
            <li class="Navbar__Content__List__Item">
              <router-link
                :to="{ path: '/', hash: 'deportes' }"
                >Programas Deportivos</router-link
              >
            </li>
            <li class="Navbar__Content__List__Item">
              <v-menu offset-y style="z-index: 34242">
                <template v-slot:activator="{ on, attrs }">
                  <li
                    v-bind="attrs"
                    v-on="on"
                  >
                    Lanzamientos
                  </li>
                </template>
                <v-list style="z-index: 34242">
                  <v-list-item>
                    <router-link
                      style="width: 100%"
                      :to="{ path: '/lanzamiento-regionconectada' }"
                      >Región Conectada</router-link
                    >
                  </v-list-item>
                  <v-list-item>
                    <router-link
                      style="width: 100%"
                      :to="{ path: '/lanzamiento-iduam' }"
                      >Lanzamiento Iduam</router-link
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </li>
            <li class="Navbar__Content__List__Item"><router-link :to="{ path: '/denuncias-destacadas' }">Denuncias</router-link></li>
          </ul>
        </div>

             <v-app-bar-nav-icon class="Navbar__Toggler ml-auto" @click="drawer = true"></v-app-bar-nav-icon>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary style="height: 100vh;position: fixed;">
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
                  <router-link :to="{ path: '/' }">
            <v-img
              contain
              lazy-src="../../assets/images/logo.svg"
              max-height="184"
              max-width="262"
              src="../../assets/images/logo.svg"
            ></v-img>
          </router-link>
          <v-list-item style="margin-top: 32px;">
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-title><router-link :to="{ path: '/nosotros' }">Nosotros</router-link></v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-video</v-icon>
            </v-list-item-icon>
            <v-list-item-title><router-link :to="{ path: '/videos' }">Videos</router-link></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-expansion-panels accordion>
            <v-expansion-panel>
            <v-expansion-panel-header style="padding: 0; width: 145px;">
            <v-list-item-icon>
              <v-icon>mdi-poll</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Encuestas</v-list-item-title>
            </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list-item-title><router-link
                      style="width: 100%"
                      :to="{ path: '/encuestas/incivilidades' }"
                      >Incivilidades</router-link>
                      </v-list-item-title>
                    <v-list-item-title style="margin-top: 20px;"><router-link
                      style="width: 100%;"
                      :to="{ path: '/encuestas/salud' }"
                      >Salud</router-link
                    ></v-list-item-title>
                </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-shield</v-icon>
            </v-list-item-icon>
            <v-list-item-title><router-link
                :to="{ path: '/', hash: 'seguridad' }"
                >Programas de Seguridad</router-link
              ></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-medical-bag</v-icon>
            </v-list-item-icon>
            <v-list-item-title><router-link
                :to="{ path: '/', hash: 'salud' }"
                >Programas de Salud</router-link
              ></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-basketball</v-icon>
            </v-list-item-icon>
            <v-list-item-title><router-link
                :to="{ path: '/', hash: 'deportes' }"
                >Programas Deportivos</router-link
              ></v-list-item-title>
          </v-list-item>

            <v-list-item>
            <v-expansion-panels accordion>
            <v-expansion-panel>
            <v-expansion-panel-header style="padding: 0; width: 165px;">
            <v-list-item-icon>
              <v-icon>mdi-rocket-launch</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lanzamientos</v-list-item-title>
            </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list-item-title><router-link
                      style="width: 100%"
                      :to="{ path: '/lanzamiento-regionconectada' }"
                      >Región Conectada</router-link
                    >
                      </v-list-item-title>
                    <v-list-item-title style="margin-top: 20px;"><router-link
                      style="width: 100%"
                      :to="{ path: '/lanzamiento-iduam' }"
                      >Lanzamiento Iduam</router-link
                    ></v-list-item-title>
                </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
          </v-list-item>
            <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-comment-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title><router-link :to="{ path: '/denuncias-destacadas' }">Denuncias</router-link></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      group: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.Navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &__Content {
    display: flex;
    align-items: center;
    gap: 12px;
    &__List {
      display: none;
      &__Item {
        cursor: pointer;
        font-size: 12px;
      }
    }
  }
  &__Toggler {
    display: flex;
  }
}

@media (min-width: 1200px) {
    .Navbar {
        &__Content {
            &__List {
                display: flex;
                gap: 12px;
                list-style: none;
            }
        }
        &__Toggler {
            display: none;
        }
    }
}
.v-expansion-panel::before {
    box-shadow: none;
}
</style>
