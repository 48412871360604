<template>
  <div>
    <div class="container" id="main" >
      <Header2/>
      <section style="margin-top: 80px; margin-bottom: 100px">
        <div class="mx-auto logos" style="max-width: 400px; padding: 0;">
            <div style="display:flex;gap: 20px; align-items:center;padding: 0; flex-wrap:wrap;">
          <v-img
            :src="require('../../assets/images/logo.svg')"
            class="my-3"
            contain
            style="width:20%"
          />
          <v-img
            :src="require('../../assets/logo-arica.png')"
            class="my-3"
            contain
            style="width:5%"
          />
          <v-img
            :src="require('../../assets/logo-core.png')"
            class="my-3"
            contain
            style="width:25%"
          />
            </div>
        </div>
        <h2>Lanzamiento Iduam</h2>
        <div v-show="loader">
          <div style="margin-top: 40px">
            <iframe
              class="iframe-video"
              src="https://www.youtube.com/embed/kwWMOYMMMMI?si=2ilgJmFwDMRLr4Cr"
              title="Lanzamiento Iduam"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <div class="Galeria">
            <img
              @click="openImage('1.jpeg')"
              class="img-sm"
              src="../../assets/images/galeriaIduam/1.jpeg"
            />
            <img
              @click="openImage('2.jpeg')"
              class="img-sm"
              src="../../assets/images/galeriaIduam/2.jpeg"
            />
            <img
              @click="openImage('3.jpeg')"
              class="img-sm"
              src="../../assets/images/galeriaIduam/3.jpeg"
            />
            <img
              @click="openImage('4.jpeg')"
              class="img-sm"
              src="../../assets/images/galeriaIduam/4.jpeg"
            />
          </div>

          <div class="Galeria" style="margin-top: 0">
            <img
              @click="openImage('5.jpeg')"
              class="img-sm"
              src="../../assets/images/galeriaIduam/5.jpeg"
            />
            <img
              @click="openImage('6.jpeg')"
              class="img-sm"
              src="../../assets/images/galeriaIduam/6.jpeg"
            />
            <img
              @click="openImage('7.jpeg')"
              class="img-sm"
              src="../../assets/images/galeriaIduam/7.jpeg"
            />
            <div style="position: relative">
              <img
                @click="openVideo('video1.mp4')"
                class="img-sm"
                src="../../assets/images/galeriaIduam/previewVideo1.png"
              />
              <v-icon
                @click="openVideo('video1.mp4')"
                style="
                  position: absolute;
                  left: 50%;
                  font-size: 67px;
                  top: 50%;
                  transform: translate(-50%, -50%);
                "
                large
                color="white darken-2"
              >
                mdi-play-circle
              </v-icon>
            </div>
          </div>

          <div class="Galeria" style="margin-top: 0">
            <div style="position: relative">
              <img
                @click="openVideo('video2.mp4')"
                class="img-sm"
                src="../../assets/images/galeriaIduam/previewVideo2.png"
              />
              <v-icon
                @click="openVideo('video2.mp4')"
                style="
                  position: absolute;
                  left: 50%;
                  font-size: 67px;
                  top: 50%;
                  transform: translate(-50%, -50%);
                "
                large
                color="white darken-2"
              >
                mdi-play-circle
              </v-icon>
            </div>
<!--             <div style="position: relative">
              <img
                @click="openVideo('video3.mp4')"
                class="img-sm"
                src="../../assets/images/galeriaIduam/previewVideo3.png"
              />
              <v-icon
                @click="openVideo('video3.mp4')"
                style="
                  position: absolute;
                  left: 50%;
                  font-size: 67px;
                  top: 50%;
                  transform: translate(-50%, -50%);
                "
                large
                color="white darken-2"
              >
                mdi-play-circle
              </v-icon>
            </div> -->
          </div>

          <v-dialog v-model="dialog" width="800">
            <div
              style="
                position: relative;
                background: black;
                display: flex;
                justify-content: center;
              "
            >
              <v-icon
                @click="dialog = false"
                style="position: absolute; right: 10px"
                class="close-icon"
                large
                color="white darken-2"
              >
                mdi-close
              </v-icon>
              <img style="height: 450px" :src="selectedImage" alt="" />
            </div>
          </v-dialog>

          <v-dialog v-if="dialogVideo" v-model="dialogVideo" width="800">
            <div style="position: relative; background-color: black">
              <v-icon
                @click="dialogVideo = false"
                class="video-icon"
                large
                color="white darken-2"
                style="position: absolute; right: 10px"
              >
                mdi-close
              </v-icon>
              <video autoplay style="width: 100%; height: 400px" controls>
                <source :src="selectedVideo" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </v-dialog>

        <h2 id="testimoniales" style="margin-top: 50px;">Nuevo lanzamiento Iduam en GORE Arica y Parinacota. 30/10/2023 </h2>

          <div style="margin-top: 40px">
            <iframe
              src="https://www.youtube.com/embed/h9OToKxzftc?si=3yB08EvWp_IuGWQt"
              class="iframe-video"
              title="Lanzamiento Iduam"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div v-show="!loader">
          <div class="text-center mt-5">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header2 from "../commons/Header2.vue";
import Footer from "../commons/Footer.vue";

export default {
  components: {
    Header2,
    Footer,
  },
  data() {
    return {
      selectedImage: "",
      selectedVideo: "",
      dialog: false,
      dialogVideo: false,
      loader: false,
    };
  },
  methods: {
    openImage(filename) {
      this.selectedImage = require("@/assets/images/galeriaIduam/" + filename);
      this.dialog = true;
    },
    openVideo(filename) {
      this.selectedVideo = require("@/assets/images/galeriaIduam/" + filename);
      this.dialogVideo = true;
    },
  },
  mounted() {
    setTimeout(() => {
      this.loader = true;


    }, 1000);

    setTimeout(() => {
/*       const bottom = document.body.scrollHeight
      console.log(bottom); */
      window.scrollTo(0, 0);
      
    }, 1500);


/*     setTimeout(() => {
      
    }, 1000); */
    
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "/js/webflow.js");
    plugin.setAttribute("type", "text/javascript");
    document.head.appendChild(plugin);
    //
    const plugin2 = document.createElement("script");
    plugin2.setAttribute(
      "src",
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62f4510e73201624361551ab"
    );
    plugin2.setAttribute(
      "integrity",
      "sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    );
    plugin2.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(plugin2);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/valcar.webflow.css";

.Galeria {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

@media screen and (min-width: 600px) {
  .Galeria {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

@media screen and (min-width: 800px) {
  .Galeria {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

.img-sm:hover {
  filter: brightness(50%);
  cursor: pointer;
}
.img-sm {
  width: 100%;
  height: 400px;
}
.close-icon {
  position: absolute;
  right: 10px;
  opacity: 0.8;
  &:hover {
    cursor: pointer;
  }
}

.iframe-video {
  height: 300px;
  width: 100%;
}

@media (min-width: 560px) {
  .iframe-video {
    width: 80%;
    height: 500px;
    max-width: 1200px;
  }
}

html {
  scroll-behavior: smooth;
}
</style>
