<template>
  <Pie id="my-chart-id" :options="chartOptions" :chartData="chartData" />
</template>

<script>
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "PieChart",
  components: { Pie },
  data() {
    return {
      chartData: {
        labels: [
          "Hipertensión Arterial",
          "Diabetes",
          "Obesidad",
          "Todas las anteriores",
          "Otras",
        ],
        datasets: [
          { data: [1274, 798, 412, 521, 1006], 
          backgroundColor: ['#061728', '#CCD1DE', '#4151D2', '#4D88E9', '#6DB79F'] },
        ],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },
  computed: {
    /*     colors() {
      const arr = [];
      for (const key in this.chartData.labels) {
        arr.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
      }
      return arr;
    }, */
  },
};
</script>
