import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-logo-left wf-section",staticStyle:{"z-index":"200"}},[_c('div',{staticClass:"navbar-logo-left-container shadow-three w-nav",attrs:{"data-animation":"default","data-collapse":"medium","data-duration":"400","data-easing":"ease","data-easing2":"ease","role":"banner"}},[_c('div',[_c('div',{staticClass:"navbar-wrapper"},[_vm._m(0),_c('nav',{staticClass:"nav-menu-wrapper w-nav-menu",staticStyle:{"align-items":"left"},attrs:{"role":"navigation"}},[_c('ul',{staticClass:"nav-menu-two w-list-unstyled",attrs:{"role":"list"}},[_c('li',[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/nosotros'}}},[_vm._v("Nosotros")])],1),_c('li',[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/videos'}}},[_vm._v("Videos")])],1),_c(VMenu,{staticStyle:{"z-index":"34242"},attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('li',_vm._g(_vm._b({style:(!_vm.$vuetify.breakpoint.mobile
        ? 'font-size: 12px; padding-top:1px;'
        : 'font-size: 22px; margin-left:8px;')},'li',attrs,false),on),[_vm._v(" Encuestas ")])]}}])},[_c(VList,[_c(VListItem,[_c('router-link',{staticClass:"nav-link",staticStyle:{"width":"100%"},attrs:{"to":{ path: '/encuestas/incivilidades' }}},[_vm._v("Incivilidades")])],1),_c(VListItem,[_c('router-link',{staticClass:"nav-link",staticStyle:{"width":"100%"},attrs:{"to":{ path: '/encuestas/salud' }}},[_vm._v("Salud")])],1)],1)],1),_c('li',{attrs:{"id":"item-nav3"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/', hash: 'seguridad' }}},[_vm._v("Programas de Seguridad")])],1),_c('li',{attrs:{"id":"item-nav4"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/', hash: 'salud' }}},[_vm._v("Programas de Salud")])],1),_c('li',{attrs:{"id":"item-nav5"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/', hash: 'deportes' }}},[_vm._v("Programas Deportivos")])],1),_c('li',[_c(VMenu,{staticStyle:{"z-index":"34242"},attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('li',_vm._g(_vm._b({style:(!_vm.$vuetify.breakpoint.mobile
        ? 'font-size: 12px;padding-top: 2px;'
        : 'font-size: 22px; margin-left:8px;')},'li',attrs,false),on),[_vm._v(" Lanzamientos ")])]}}])},[_c(VList,{staticStyle:{"z-index":"34242"}},[_c(VListItem,[_c('router-link',{staticClass:"nav-link",staticStyle:{"width":"100%"},attrs:{"to":{ path: '/lanzamiento-regionconectada' }}},[_vm._v("Región Conectada")])],1),_c(VListItem,[_c('router-link',{staticClass:"nav-link",staticStyle:{"width":"100%"},attrs:{"to":{ path: '/lanzamiento-iduam' }}},[_vm._v("Lanzamiento Iduam")])],1)],1)],1)],1),_c('li',{staticClass:"denuncias-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/denuncias-destacadas' }}},[_vm._v("Denuncias")])],1),_c('li',{staticClass:"d-flex d-sm-flex d-md-none",staticStyle:{"height":"48px"},attrs:{"id":"item-nav2"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/', hash: '#iduam-description' }}},[_vm._v("Descripción Iduam")])],1),_c('li',{staticClass:"d-flex d-sm-flex d-md-none",staticStyle:{"height":"48px"},attrs:{"id":"item-nav"}},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ path: '/', hash: '#region-description' }}},[_vm._v("Descripción Región Conectada")])],1)],1)]),_vm._m(1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand w-nav-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"image-6",attrs:{"src":require("../../assets/images/logo.svg"),"loading":"lazy","height":"90","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-button w-nav-button",attrs:{"id":"boton"}},[_c('div',{staticClass:"w-icon-nav-menu"})])
}]

export { render, staticRenderFns }